import React, { useState, useEffect } from "react";
import styles from "../../../pages/Shipment/shimpment.module.css";
import Layout from "../../../pages/Shipment/Layout";
import { APICALL } from "../../../services/ApiServices";
import { fetchList, fetchBpwiseList } from "../../../routes/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import { ConfigConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";
import { t } from "../../../pages/Translations/TranslationUtils";
import Card from "../../molecules/ShipmentDashboardCard";
import IconData from "../../../static/IconData";
import { WorkflowStageNames } from "../../../utils/constants/WorkflowStageNames";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import { useUserContext } from "../../../routes/Contextlib";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import { CommonConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";
import CommonServices from "../../../services/CommonServices";

// Define a type for the MerchRack item
type MerchRackItems = {
  value: number;
  label: string;
};

const PrePublishOrganism = () => {
  const navigate = useNavigate();
  const localdata = CommonShipmentServices.getPostData();
  const { user } = useUserContext();
  const [state, setState] = useState({
    merchRack: [] as any[],
    data: [] as any,
    selectedDataEntryIds: [] as any[],
    selectedMerchRackDataEntries: null as any,
    selctedMerchRackTitle: "",
    showPopup: false,
    selectAllItems: false,
    totaltime: 0,
    timerrunning: true,
    activetab: "notpublished",
    selectedmerchrack: null as any,
  });

  useEffect(() => {
    if (user != null) {
      fetchData();
      timeTracker();

      return () => {
        CommonShipmentServices.saveTimerApi(
          'bp',
          localdata?.brand_partner_id,
          user?.user_id,
          WorkflowStageNames.PREPUBLISH,
          false
        );
      };
    }
  }, [user != null]);

  const timeTracker = async () => {
    try {
      let time = await CommonShipmentServices.saveTimerApi(
        'bp',
        localdata?.brand_partner_id,
        user?.user_id,
        WorkflowStageNames.PREPUBLISH
      );
    }
    catch (error) {

    }
  }


  const handleMerchRack = async (item: any) => {
    if (state.timerrunning) {
      navigate(`/publish/rack?id=${item.merch_rack_id}&tab=active`);
    }
  };

  const fetchData = async () => {
    try {
      if (state.merchRack == null || state.merchRack.length == 0) {
        const postData = {
          method: "POST",
          data: {
            list: [
              ConfigConstants.ALL_BPWISE_MERCH_RACK,
            ],
            bp_id: [localdata?.brand_partner_id],
          },
        };
        const response = await APICALL.service(
          fetchBpwiseList,
          "POST",
          postData,
          true
        );
        setState((prevState) => ({ ...prevState, merchRack: CommonServices.sortAlphabattically(response?.data?.cd_merch_rack, 'label') }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout
      pagename="Publishing"
      logo={true}
      timer={false}
    >
      <div className="flex-1 overflow-auto">
        <div className="h-100 overflow-auto border rounded shadow">
          {state.selectedmerchrack == null &&
            <div className="row p-4 position-relative m-0">
              {state.merchRack.map((item) => (
                <div className={`${styles.prepublish_card_block} p-3 `}>
                  <Card
                    key={item?.merch_rack_id}
                    title={item?.label}
                    icon={IconData.merchrack}
                    className={`${styles.prepublish_card} border rounded px-5 p-4`}
                    handleOnclick={() => handleMerchRack(item)}
                    smallCard={true}
                    main_class="w-100 cursor-pointer"
                  />
                </div>))}
            </div>
          }
        </div>
      </div>
      <div className="">
        <ActionButtonGroup
          backTitle={t(CommonConstants.BACK)}
          handleBackClick={() => navigate('/shipment-dashboard')}
        />
      </div>
    </Layout>
  );
};

export default PrePublishOrganism;
