import React, { useState, useEffect } from 'react'
import Layout from '../../pages/Shipment/Layout';
import { ImageView } from '../molecules/QualityControlMolecules/ImageView';
import { ProductInfo } from '../molecules/QualityControlMolecules/ProductInfo';
import { APICALL } from '../../services/ApiServices';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import { getrepair, fetchList, updaterepair, fetchEach } from '../../routes/ApiEndPoints';
import { CommonConstants, ConfigConstants } from '../../pages/Shipment/Constants/ShipmentConstants';
import ViewIssueMolecule from '../molecules/ViewIssueMolecule';
import { t } from "../../pages/Translations/TranslationUtils";
import Button from '../atoms/Button';
import { useNavigate } from 'react-router-dom';
import customAlert from '../atoms/CustomAlert';
import RepairIssue from '../molecules/RepairMolecules/RepairIssue';
import IconData from '../../static/IconData';
import { WorkflowStageNames } from '../../utils/constants/WorkflowStageNames';
import ScreenDisable from '../../utils/ScreenDisable';
import Popup from '../../utils/popup';

const RepairOrganism: React.FC = () => {
    const navigate = useNavigate();
    const localdata = CommonShipmentServices.getPostData();
    const bpDetails: any = JSON.parse(localStorage?.getItem('bp_details') ?? '{}');

    const searchParams = new URLSearchParams(location.search);
    const editid = Number(searchParams.get("edit"));
    const [showpopup, setShowPopup] = useState<boolean>(false);
    const [repairdata, setrepairdata] = useState<any>({})
    const [issuedetails, setissuedetails] = useState<any>()
    const [selectedIssueList, setSelectedIssueList] = useState<any>([]);
    const [viewIssueBlock, setviewIssueBlock] = useState(false);
    const [viewIssuesList, setviewIssuesList] = useState(true);
    const [configdata, setConfigData] = useState<any>([]);
    const [stage, setStage] = useState<any>();
    const [viewsave, SetViewSave] = useState<boolean>(true)
    const [totaltime, setTotalTime] = useState(0);
    const [timer, setTimer] = useState(true);
    const [imageUrl, setImageUrl] = useState({
        front_image: '',
        back_image: ''
    })
    const [dataentry, setDataEntry] = useState<any>({})
    const [commentData, setCommentData] = useState({
        type: "renewal_record",
        type_id: null,
        stagename: "repair",
        commentdata: {}
    });
    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                let postdata = {
                    "method": "POST",
                    "data": {
                        "list": [
                            ConfigConstants.ENVIRONMENT,
                            ConfigConstants.ISSUETYPE,
                            ConfigConstants.ZONE
                        ]
                    }
                }
                const response = await APICALL.service(fetchList, 'POST', postdata, true);
                if (response?.status === 200) {
                    setConfigData(response?.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchConfigData();
    }, [])

    useEffect(() => {
        if (localdata?.user_id != null && commentData?.type_id != null) {
            timeTracker(true);

            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        }
    }, [commentData?.type_id != null, localdata?.user_id != null]);

    const timeTracker = async (reloaded: boolean = false) => {
        let time = await CommonShipmentServices.saveTimerApi(
            'renewal',
            commentData?.type_id,
            localdata?.user_id,
            WorkflowStageNames.REPAIR,
            reloaded
        );
    }

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                let postdata = JSON.parse(JSON.stringify(localdata));
                postdata.repair_id = editid;

                const response = await APICALL.service(getrepair, 'POST', postdata);
                if (response?.status === 200) {
                    if (response?.data?.repairdata.length > 0) {
                        setStage(response?.stage)
                        if (response?.data?.image && response?.data?.image?.length > 0) {
                            setImageUrl({
                                ...imageUrl,
                                front_image: response?.data?.image[0]?.front_image,
                                back_image: response?.data?.image[0]?.back_image,
                            })
                        }
                        checkRepairableItems(response?.data?.repairdata[0]?.repair_issue, null)
                        setSelectedIssueList(response?.data?.repairdata[0]?.repair_issue)
                        if (response?.data?.repairdata[0]?.renewal_record && response?.data?.repairdata[0]?.renewal_record?.renewal_product_info) {
                            setCommentData({ ...commentData, type_id: response?.data?.repairdata[0]?.renewal_record?.renewal_id, commentdata: response?.commentdata })
                            const datarequest = {
                                "method": "POST",
                                "data": {
                                    data: response?.data?.repairdata[0]?.renewal_record
                                }
                            }
                            const dataentry = await APICALL.service(fetchEach, 'POST', datarequest, true);
                            if (dataentry?.status == 200) {
                                setDataEntry(dataentry?.data);
                            }
                        }
                        setrepairdata(response?.data?.repairdata[0])
                    } else {
                        navigate(`/shipment-dashboard`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchFormData();
    }, [editid != undefined])

    const handleEdit = (item: any, edit?: boolean) => {
        if (timer && (item.repairable == null || edit)) {
            if (issuedetails == null || item.issues_id != issuedetails?.issue_type_id) {
                let data = CommonShipmentServices.getFilteredObject(configdata['IssueType'] ?? [], 'issue_type_id', item.issues_id)
                item.issuename = data[0]
                item.serial_number = repairdata?.serial_number
                setissuedetails(item)
                updateState()
            }
        }
    }

    const checkRepairableItems = (repairissue: any, type: any) => {
        const repairablestat = repairissue.length > 0 ?
            repairissue.some((item: any) => item.repairable === type) :
            false;
        if (type != "no") {
            SetViewSave(repairablestat)
        } else {
            return repairablestat;
        }
    }

    const updateState = () => {
        setviewIssueBlock(!viewIssueBlock)
        setviewIssuesList(!viewIssuesList)
    }

    const handleRepair = (data: any) => {
        if (timer) {
            setSelectedIssueList(data?.repairissues)
            checkRepairableItems(data?.repairissues, null)
            updateState()
        }
    }

    const handleSubmit = async (event: any) => {
        let res = checkRepairableItems(selectedIssueList, "no")
        if (res) {
            setShowPopup(true);
        } else {
            submitData(false);
        }
    }

    const submitData = async (passable: boolean = false) => {
        try {
            setShowPopup(false)
            let postdata: any = {
                ...localdata,
                ...repairdata,
                tier: bpDetails.tier,
                passable: passable
            }
            const response = await APICALL.service(updaterepair, 'POST', postdata);

            if (response?.status == 200) {
                navigate(`/shipment/repair/manage`);
            } else {
                customAlert(
                    "error",
                    t("Error while saving the repair data"),
                    6000
                )
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBack = () => {
        navigate(`/shipment/repair/manage`);
    }

    const setTimerRunning = (timer: boolean) => {
        setTimer(timer);
    }

    return (
        <Layout
            pagename={('Repair')}
            logo={true}
            timer={true}
            comments={commentData}
            hold={true}
            ongoing
            totaltime={totaltime}
            stage={WorkflowStageNames.REPAIR}
            setTimerRunning={setTimerRunning}
        >
            <div className="row position-relative m-0 h-75">
                <ScreenDisable display='none' />
                {/* left block start */}
                <div className="col-6 h-100 overflow-auto">
                    <div className="py-4 pb-2 px-5 border rounded shadow h-100 d-flex flex-column">
                        <h3 className='text-gray text-break'>{repairdata?.serial_number}</h3>

                        <div className="flex-1 overflow-auto">
                            {/* image block start */}
                            <ImageView
                                disable={false}
                                front_image={imageUrl?.front_image}
                                back_image={imageUrl?.back_image}
                            />
                            {/* image block ends */}
                        </div>

                        {/* Product info block start */}
                        <ProductInfo
                            dataentry={dataentry?.renewal_product_info} />
                        {/* Product info block end  */}

                    </div>
                </div>
                {/* left block ends */}

                <div className="col-6 h-100 overflow-auto">
                    <div className="py-4 pb-2 px-xxl-5 px-3 border rounded shadow h-100 position-relative">
                        {/* right block to create issue starts */}
                        {viewIssueBlock &&
                            <div className="d-flex flex-column justify-content-between h-100 overflow-auto" >
                                <div className="flex-1 overflow-auto">
                                    <RepairIssue
                                        repairid={repairdata?.repair_id}
                                        issuedetails={issuedetails}
                                        handleRepair={handleRepair}
                                    />
                                </div>
                                <div className="mt-2 d-flex justify-content-between">
                                    <Button
                                        title={t(CommonConstants.BACK)}
                                        className="back-btn shadow-none"
                                        handleClick={() => updateState()}
                                    />
                                </div>
                            </div>
                        }
                        {/* right block to create issue ends */}

                        {/* right block to view issue starts */}
                        {viewIssuesList &&
                            <>
                                <div className="d-flex flex-column justify-content-between overflow-auto h-100" >
                                    <div className="flex-1 overflow-auto">
                                        {(selectedIssueList && Object.keys(configdata)?.length > 0
                                            && Object.keys(selectedIssueList)?.length > 0) ? (
                                            <div>
                                                <div>
                                                    <div className="row m-0">
                                                        {Object.values(selectedIssueList)?.map((item: any) => (
                                                            <React.Fragment key={item.quality_control_issues.quality_control_issue_id}>
                                                                <div className="mb-3 col-12 col-xl-6">
                                                                    <ViewIssueMolecule
                                                                        key={item.quality_control_issues.quality_control_issue_id}
                                                                        item={item.quality_control_issues}
                                                                        configdata={configdata}
                                                                        handleAction={() => handleEdit(item)}
                                                                        handleEditAction={() => handleEdit(item, true)}
                                                                        action={(item.repairable != null) ? (item.repairable == 'yes' ? IconData.SuccessGreen : IconData.Cancel_red) : IconData.RepairIcon}
                                                                        actionedit={(item.repairable != null) ? IconData.EditIcon : false}
                                                                        idname="issue_id"
                                                                        object={true}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            // : <div className={`${styles.itemsblock} text-center`} >{t("No issues reported")}</div>
                                            : <div className={`text-center`} >{t("No issues reported")}</div>
                                        }
                                    </div>
                                    <div className="mt-2 d-flex justify-content-between">
                                        <Button
                                            title={t(CommonConstants.BACK)}
                                            className="back-btn shadow-none"
                                            handleClick={handleBack}
                                        />
                                        {(stage == dataentry.stage_id && !viewsave) &&
                                            <Button
                                                title={t(CommonConstants.SAVE_AND_SCAN_NEXT)}
                                                className="submit-btn shadow-none"
                                                handleClick={handleSubmit}
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {/* right block to view issue ends */}
                    </div>
                </div>
            </div>
            {showpopup &&
                <Popup
                    title={t("Confirmation")}
                    body={t("Is this item passable") + "?"}
                    yestext={t(CommonConstants.YES)}
                    notext={t(CommonConstants.NO)}
                    submit={() => submitData(false)}
                    cancel={() => submitData(true)}
                />
            }
        </Layout>
    )
}

export default RepairOrganism;
