import { InputWithLabel } from "../../components/molecules/InputWithLabel";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import { t } from "../Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FormValidation from "../../services/FormValidation";
import { APICALL } from "../../services/ApiServices";
import {
  fetchProductCatalogue,
  updateCatalogue,
} from "../../routes/ApiEndPoints";
import customAlert from "../../components/atoms/CustomAlert";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import Button from "../../components/atoms/Button";
import CommonServices from "../../services/CommonServices";
import { useUserContext } from "../../routes/Contextlib";
import { CONSTANTS } from "../../applicationConstants/AppConstants";
import AccessControl from "../../services/AccessControl";

type ProductState = {
	id: any;
	brand_partner_id: any;
	product_catalogue_info_id: any,
	original_catalogue_info_id: any,
	brand_title: string,
	style_number: string,
	product_type: string,
	gender: string,
	country: string,
	msrp: string,
	color_name: string,
	hex_color_code: string,
	product_description: string,
	product_group: string,
	currency: string,
	renewal_fee: string,
	size: string,
	weight: string,
	fabric_content: string,
	sku_number: string,
	upc: string,
};

const initialProductState: ProductState = {
	brand_title: "",
	style_number: "",
	product_type: "",
	gender: "",
	country: "",
	msrp: "",
	color_name: "",
	hex_color_code: "",
	product_description: "",
	product_group: "",
	currency: "",
	renewal_fee: "",
	size: "",
	weight: "",
	fabric_content: "",
	sku_number: "",
	upc: "",
	id: null,
	brand_partner_id: null,
	product_catalogue_info_id: null,
	original_catalogue_info_id: null
};

interface FormErrors {
	style_number: string,
	product_type: string,
	gender: string,
	country: string,
	msrp: string,
	color_name: string,
	hex_color_code: string,
	product_description: string,
	product_group: string,
	currency: string,
	renewal_fee: string,
	size: string,
	weight: string,
	fabric_content: string,
}

const EditProductCatalogue = () => {
	const navigate = useNavigate();
	const { user } = useUserContext();
	const brand = JSON.parse(localStorage.getItem('catalogue_bp') ?? '{}');
	const searchParams = new URLSearchParams(location.search);
	const editId = searchParams.get("edit");
	const status = searchParams.get("status");
	const [refresh, setRefresh] = useState(false);
	const [tabs, setTabs] = useState({
		tabdata: [],
		activetab: "",
		importedon: null,
		status: "",
		action_status: ""
	});
	const [productState, setProductState] =
		useState<ProductState>(initialProductState);
	const [errors, setErrors] = useState<FormErrors>({
		style_number: "",
		product_type: "",
		gender: "",
		country: "",
		msrp: "",
		color_name: "",
		hex_color_code: "",
		product_description: "",
		product_group: "",
		currency: "",
		renewal_fee: "",
		size: "",
		weight: "",
		fabric_content: ""
	});

	const productCatalogueFieldNames = [
		{ key: 'style_number', name: 'Style Number', required: true },
		{ key: 'product_type', name: 'Product Type', required: true },
		{ key: 'gender', name: 'Gender', required: false },
		{ key: 'country', name: 'Country', required: true },
		{ key: 'msrp', name: 'Msrp', required: true },
		{ key: 'color_name', name: 'Color Name', required: true },
		{ key: 'hex_color_code', name: 'Hex Color Code', required: true },
		{ key: 'product_description', name: 'Product Description', required: true },
		{ key: 'product_group', name: 'Product Group', required: true },
		{ key: 'currency', name: 'Currency', required: true },
		{ key: 'renewal_fee', name: 'Renewal Fee', required: true },
		{ key: 'size', name: 'Size', required: true },
		{ key: 'weight', name: 'Weight', required: true },
		{ key: 'fabric_content', name: 'Fabric Content', required: true }
	];	

	useEffect(() => {
		if (editId !== null && status !== null) {
			getProductCatalogue();
			setProductState((prev) => ({
				...prev,
					id: editId,
			}));
		}
	}, [editId, status]);

	useEffect(() => {
		if (productState.product_catalogue_info_id !== null) {
			getProductCatalogue();
		}
	}, [productState.product_catalogue_info_id]);

	useEffect(() => {
		const message: string = localStorage?.getItem("errorAlertMessage") ?? "";
		localStorage.removeItem("errorAlertMessage");
		if (message != "") {
			customAlert("error", t(message), 2000);
		}
	}, [refresh]);

	async function getProductCatalogue() {
		try {
			let data: any = {
				id: editId,
				brand_partner_id: brand.brand_partner_id,
			};
			if (productState.product_catalogue_info_id) data.product_catalogue_info_id = productState.product_catalogue_info_id;
			if (status === 'rejected' && tabs.activetab === "") data.rejectedtabid = status;
			let postdata = {
				method: "POST",
				data: data
			};
			const response = await APICALL.service( fetchProductCatalogue, "POST", postdata, true);
			if (response.status == 200 && response.data) {
				const responseData = response.data.data[0];
				
				setProductState((prev) => ({
					...prev,
					style_number: responseData.style_number ?? "",
					product_type: responseData.product_type ?? "",
					gender: responseData.gender ?? "",
					country: responseData.country ?? "",
					msrp: responseData.msrp ?? "",
					color_name: responseData.color_name ?? "",
					hex_color_code: responseData.hex_color_code ?? "",
					product_description: responseData.product_description ?? "",
					product_group: responseData.product_group ?? "",
					currency: responseData.currency ?? "",
					renewal_fee: responseData.renewal_fee ?? "",
					size: responseData.size ?? "",
					weight: responseData.weight ?? "",
					fabric_content: responseData.fabric_content ?? "",
					sku_number: responseData.sku_number ?? "",
					upc: responseData.upc ?? "",
					brand_title: brand?.title ?? "",
					original_catalogue_info_id: responseData.original_catalogue_info_id,
					brand_partner_id: brand.brand_partner_id,
				}));
				let tab = tabs.activetab === "" ? 'Original' : tabs.activetab;
				if ((tabs.activetab === "") && responseData?.status === 'rejected' && response.data?.tabdata?.length) {
					let index = response.data?.tabdata.length - 1;
					tab = 'version' + (index < 9 ? '-0' + index : index);
				}
				
				setTabs((prev: any) => ({
					...prev,
					tabdata: response.data?.tabdata && tabs?.tabdata?.length === 0 ? response.data?.tabdata : prev.tabdata,
					importedon: responseData?.created_at,
					status: responseData?.status,
					activetab: tab,
					action_status: responseData?.action_status
				}));
			}
		} catch (error) {
			console.log(error);
		}
	}

	const handleInputChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) => {
		const { name, value } = e.target;
		setProductState((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const removeError = (event: React.FocusEvent<HTMLInputElement>) => {
		let fieldName = (event.target as HTMLInputElement).name;
		setErrors((prevState) => ({
			...prevState,
			[fieldName]: "",
		}));
	};

	const validateForm = () => {
		const newErrors: any = { ...errors };
		let error = "";
		const columnlengthcheck = {
			names: ['product_group', 'product_description', 'style_number', 'size', 'color_name'],
			lengths: [10, 80, 15, 10, 20]
		};
		let notinclude = ['id', 'brand_partner_id', 'brand_title', 'product_catalogue_info_id', 'original_catalogue_info_id', 'gender'];
		Object.keys(productState).map((item: string) => {
			const key = item as keyof ProductState;
			if (key === 'hex_color_code') {
				let codes = CommonServices?.splitAndJoin(productState[key], ';') ?? [];
				if (codes?.length > CONSTANTS.COLORS) {
					newErrors[key] = `Only ${CONSTANTS.COLORS} hex color codes are allowed`;
				} else {
					if (FormValidation?.hasDuplicates(codes)) {
						newErrors[key] = "Hex color codes appear more than once within a single color"
					} else {
						let error = codes?.map((item: any) => FormValidation.isValidHexColor(item ?? ""));
						newErrors[key] = error?.some((item: any) => item != "") ? "Invalid Hex color codes" : "";
					}
				}
			} else if (columnlengthcheck.names?.includes(key)) {
				error = FormValidation.nameValidation(productState[key], Number(columnlengthcheck?.lengths[columnlengthcheck.names.indexOf(key)]));
				newErrors[key] = error;
			} else if (!notinclude.includes(key)) {
				error = FormValidation.nameValidation(productState[key]);
				newErrors[key] = error;
			}
		});
		setErrors(newErrors);
		return Object.values(newErrors).every((item: any) => item == "");
	};

	const handleSubmit = (event: React.FormEvent, action?: any) => {
		event.preventDefault();
		const valid = action ? true : validateForm();
		if (valid) {
			UpdateData(action);
		}
	};

	const UpdateData = async (action?: any) => {
		let data: any = { ...productState, }
		data.action = action ?? 'update';
		const postData = {
			method: "POST",
			data: { data },
		};
		const response = await APICALL.service(
			updateCatalogue,
			"POST",
			postData,
			true
		);
		if (response?.status == 200) {
			localStorage.setItem("successAlertMessage", response?.message);
			navigate(`/product-catalogue/manage?tab=${tabs.status}`);
		} else {
			localStorage.setItem("errorAlertMessage", response?.message);
		}
		setRefresh(!refresh);
	};

	const handleTabClick = (e: any, item: any) => {
		setTabs((prev) => ({ ...prev, activetab: e.target.name }));
		setProductState((prev) => ({ ...prev, product_catalogue_info_id: item }))
	};

	return (
		<AccessControl
			requiredPermissions={[
				{
					permission: "product catalogue",
					update: true,
				},
			]}
			renderNoAccess={true}
		>
		  <div className="row">
			<div className="col-md-11 ms-auto px-4 ">
				<WindowHeightComponent>
					<div className="d-flex flex-column h-100 overflow-auto">
						<div className="py-xxl-4 py-xl-3 py-lg-3 top-0 d-flex justify-content-between">
							<div className="page-title ">
								{t("Update product catalogue") + ": "}
								{productState.brand_title}
							</div>
							<div className="">
								<h5>
									{'SKU' + ": "}
									{productState.sku_number}
								</h5>
								<h5>
									{'UPC' + ": "}
									{productState.upc}
								</h5>
								<h5>
									{t("Imported on") + ": "}
									{CommonServices.showDateBasedOnFormat(tabs?.importedon ?? "", user?.date_format ?? 'dd-MM-yyyy')}
								</h5>
							</div>
						</div>
						<div className="d-flex px-2 flex-wrap">
							{
								tabs?.tabdata && tabs?.tabdata?.length > 1 &&
								tabs?.tabdata?.map((item, index) => {
									return <>
										<div className="user-tabs my-2" key={index}>
											<Button
												name={index === 0 ? 'Original' : 'version' + (index < 9 ? '-0' + (index) : index)}
												className={`border-0 tab-btn me-4 ${index === 0 ? "btn btn-success text-white":"bg-transparent"} ${(tabs.activetab === (index === 0 ? 'Original' : 'version' + (index < 9 ? '-0' + (index) : index))) ? "active" : ""}`}
												handleClick={(e) => handleTabClick(e, item)}
												title={index === 0 ? 'Original' : 'Version' + (index < 9 ? '-0' + (index) : index)}
											/>
										</div>
									</>
								})
							}
						</div>
						<form className="flex-1 d-flex flex-column h-100 overflow-auto">
							<div className="flex-1 overflow-auto pt-3">
								<div className="d-flex justify-content-between m-0 row">
									{productCatalogueFieldNames.map((item: any, index: number) => {
										const productkey = item.key as keyof FormErrors;
										const columnClass = index % 2 === 0 ? "col-md-6" : "col-md-6";
										return (
											<div className={columnClass} key={productkey}>
												<InputWithLabel
													label={`${t(item.name)} :`}
													type="text"
													name={productkey}
													value={productState[productkey]}
													className={`shadow-none form-control ${errors[productkey] !== "" ? ' border-danger' : "dd"}`}
													handleChange={handleInputChange}
													handleClick={removeError}
													isDisabled={tabs.status !== 'active' || (tabs.status === 'active' && tabs.activetab !== 'Original')}
													mandatory={item.required && (tabs.status === 'active' && tabs.activetab === 'Original')}
													error={errors[productkey] !== "" ? t(errors[productkey]) : ""}
												/>
											</div>
										);
									})}
								</div>
							</div>

							<div className="row m-0 mt-1">
								<div className="col-md-6 float-start">
									<Button
										className="back-btn shadow-none float-start text-decoration-none"
										handleClick={() => navigate(`/product-catalogue/manage?tab=${tabs.status}`)}
										title={t(CommonConstants.BACK)}
									/>
								</div>
								<div className="col-md-6 d-flex justify-content-end">
									{
										((tabs.status === 'rejected' && tabs.action_status === 'accept') 
										|| (tabs.status === 'active' && tabs.activetab === 'Original')) &&
										<Button
											className="submit-btn shadow-none  me-3"
											title={tabs.status === 'rejected' ? t("Accept") : t("Update")}
											handleClick={(e) => tabs.status === 'rejected' ? handleSubmit(e, 'accept') : handleSubmit(e)}
										/>
									}
									{
										(tabs.status === 'rejected' && tabs.action_status === 'accept')
										&&
										<Button
											className="back-btn shadow-none "
											title={t("Ignore")}
											handleClick={(e) => handleSubmit(e, 'ignore')}
										/>
									}
								</div>
							</div>
						</form>
					</div>
				</WindowHeightComponent>
			</div>
		  </div>
		</AccessControl>
	);
};

export default EditProductCatalogue;
