import React, { useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import {
  FetchLaundry,
  createLaundry,
  fetchList,
} from "../../../routes/ApiEndPoints";
import Layout from "../../../pages/Shipment/Layout";
import { useNavigate } from "react-router-dom";
import AdminCard from "../../molecules/AdminDashBoardCard";
import Popup from "../../../utils/popup";
import StainTreatmentPopupBody from "../../molecules/StainTreatmentPopupBody";
import {
  CommonConstants,
  ConfigConstants,
  LaundryInternalStages,
} from "../../../pages/Shipment/Constants/ShipmentConstants";
import { t } from "../../../pages/Translations/TranslationUtils";
import Card from "../../molecules/ShipmentDashboardCard";
import styles from "../../../pages/Shipment/shimpment.module.css";
import IconData from "../../../static/IconData";
import { Add } from "../../../static/icons/shipment/Add";
import { WorkflowStageNames } from "../../../utils/constants/WorkflowStageNames";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import ScreenDisable from "../../../utils/ScreenDisable";
import { useUserContext } from "../../../routes/Contextlib";
import CommonServices from "../../../services/CommonServices";

const StainTreatmentOrganism: React.FC = () => {
  type State = {
    stainTreatment: {
      item: number;
      stage_id: any;
      type: any;
    };
    totaltime: number;
    timerrunning: boolean;
  };

  const InitialState: State = {
    stainTreatment: {
      item: 0,
      stage_id: null,
      type: "",
    },
    totaltime: 0,
    timerrunning: true,
  };

  interface FormErrors {
    items: any;
    moveItem: any;
    general: any;
  }

  const { user } = useUserContext();
  const localdata = CommonShipmentServices.getPostData();
  const navigate = useNavigate();
  const [startPopup, setStartPopup] = useState(false);
  const [movePopup, setMovePopup] = useState(false);
  const [configData, setConfigData] = useState<any>([]);
  const [laundryData, setLaundryData] = useState<any>([]);
  const [state, setState] = useState<State>(InitialState);
  const [selectedStage, setSelectedStage] = useState<any>();
  const [selectedMoveStage, setSelectedMoveStage] = useState<any>();
  const [itemsToMove, setItemsToMove] = useState<any>();
  const [errors, setErrors] = useState<FormErrors>({
    items: null,
    moveItem: null,
    general: null,
  });
  const [refreshLaundry, setRefreshLaundry] = useState<boolean>(false);
  const [itemsUpperLimit, setItemsUpperLimit] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    if (user != null) {
      fetchConfigData();
      timeTracker(true);

      window.addEventListener('beforeunload', () => timeTracker());
      return () => {
        timeTracker();
        window.removeEventListener('beforeunload', () => timeTracker());
      }
    }
  }, [user != null]);

  const timeTracker = async (reloaded: boolean = false) => {
    let time = await CommonShipmentServices.saveTimerApi(
      "bp",
      localdata?.brand_partner_id,
      user?.user_id,
      WorkflowStageNames.INTERNALLAUNDRY,
      reloaded
    );
  };

  const fetchConfigData = async () => {
    let postdata = {
      method: "POST",
      data: {
        list: [ConfigConstants.STAINTREATMENT],
      },
    };

    try {
      const response = await APICALL.service(fetchList, "POST", postdata, true);

      if (response?.status === 200) {
        let data = {
          StainTreatment: CommonServices.sortAlphabattically(response?.data?.StainTreatment)
        }
        setConfigData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLaundryData = async (postData: any) => {
    try {
      const response = await APICALL.service(FetchLaundry, "POST", postData);
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = {
          ...localdata,
        };
        const laundrydata = await fetchLaundryData(postData);
        setLaundryData(laundrydata?.data);
        setTotalCount(laundrydata?.stagecount)
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [refreshLaundry]);

  const handlePopup = async (item: any) => {
    if (state.timerrunning) {
      const remainigItems = await calculateRemainingItemsCount();
      setItemsUpperLimit(remainigItems);
      if (remainigItems > 0) {
        setErrors((prev) => ({ ...prev, items: null }));
      } else {
        setErrors((prev) => ({ ...prev, items: t("No items available to add") }))
      }
      setStartPopup(true);
      setState((prevState) => ({
        ...prevState,
        stainTreatment: {
          ...prevState.stainTreatment,
          stage_id: item.stain_treatment_id,
          type: "stain_treatment",
        },
      }));
    }
  };

  const calculateRemainingItemsCount = async () => {
    const activeCount = laundryData?.reduce((total: any, item: any) => {
      if (item.finish_status === false) {
        return total + item.items;
      } else {
        return total;
      }
    }, 0);

    const remainigItems = totalCount - activeCount;
    return remainigItems;
  };

  const handleItemChange = async (newCount: number, type: string) => {
    if (type === "item" && newCount > itemsUpperLimit) {
      setErrors((prev) => ({
        ...prev,
        items: t("Number exceeds available counts"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, items: null }));
      setState((prevState) => ({
        ...prevState,
        stainTreatment: {
          ...prevState.stainTreatment,
          item: newCount,
        },
      }));
    }
  };

  const saveStainTreatment = () => {
    if (errors.items === null) {
      createInternalLaundry({
        ...localdata,
        stage_id: state.stainTreatment.stage_id,
        type: "stain_treatment",
        items: state.stainTreatment.item,
        start_time: new Date(),
        end_time: new Date(),
        time: 0,
        moved_to_washing: false,
        finish_status: false,
        created_by: user?.user_id ?? "",
      });
    }
  };

  const createInternalLaundry = async (postData: any) => {
    try {
      const response = await APICALL.service(createLaundry, "POST", postData);

      if (response?.status === 200) {
        setRefreshLaundry(!refreshLaundry);
        setStartPopup(false);
        setMovePopup(false);
        setSelectedMoveStage(null);
        setState((prevState) => ({
          ...prevState,
          stainTreatment: {
            ...prevState.stainTreatment,
            item: 0,
            stage_id: null,
            type: "",
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkItemsToMove = async (item: any) => {
    if (state.timerrunning) {
      setErrors((prev) => ({
        ...prev,
        general: null,
      }));
      setState((prevState) => ({
        ...prevState,
        stainTreatment: {
          ...prevState.stainTreatment,
          stage_id: null,
        },
      }));
      setSelectedStage(item.stain_treatment_id);

      const itemsCount = laundryData?.filter(
        (rem: any) =>
          rem.stage_id == item.stain_treatment_id &&
          rem.finish_status === false &&
          rem.type === "stain_treatment"
      )[0]?.items;

      setItemsToMove(itemsCount);

      if (itemsCount > 0) {
        setErrors((prev) => ({ ...prev, moveItem: null }));
      } else {
        setErrors((prev) => ({ ...prev, moveItem: t("No items to move") }));
      }
      setMovePopup(true);
    }
  };

  const prepareItemsToMove = async (item: any) => {
    setErrors((prev) => ({
      ...prev,
      general: null,
    }));
    setSelectedMoveStage(item.stage_id);
    setState((prevState) => ({
      ...prevState,
      stainTreatment: {
        ...prevState.stainTreatment,
        item: itemsToMove,
        stage_id: item.stage_id,
      },
    }));
  };

  const moveItems = async () => {
    if (state.stainTreatment.stage_id) {
      const result = await createInternalLaundry({
        ...localdata,
        stage_id: state.stainTreatment.stage_id,
        type: "washer",
        items: state.stainTreatment.item,
        finish_status: false,
        moved_to_washing: false,
        created_by: user?.user_id ?? "",
        update_previous: selectedStage,
        previous_type: "stain_treatment",
      });
      setErrors((prev) => ({
        ...prev,
        general: null,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        general: t("Please select a stage to move"),
      }));
    }
  };

  const getStageWiseCount = (stage_id: any) => {
    let count = 0;
    if (laundryData.length > 0) {
      count = laundryData?.reduce((total: any, item: any) => {
        if (
          item.finish_status === false &&
          item.stage_id == stage_id &&
          item.moved_to_washing === false &&
          item.type === "stain_treatment"
        ) {
          return total + item.items;
        } else {
          return total;
        }
      }, 0);
    }
    return count;
  };

  const setTimerRunning = (timer: boolean) => {
    setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
  };

  return (
    <Layout
      pagename={t("Stain treatment")}
      logo={true}
      timer={true}
      ongoing
      stage={WorkflowStageNames.INTERNALLAUNDRY}
      // totaltime={state.totaltime}
      setTimerRunning={setTimerRunning}
    >
      {/* title end*/}
      <div className={`d-flex flex-column ${styles.strain_body} flex-1 overflow-auto`}>
        <div className="p-4 flex-1 shadow border rounded font_Brandon_medium  position-relative overflow-auto">
          <ScreenDisable display="none" />
          <div className="h-100 overflow-auto">
            <div className="d-flex max-content-height w-100 flex-wrap">
              {configData &&
                configData.StainTreatment &&
                Object.values(configData.StainTreatment).map((item: any) => {
                  return (
                    <div key={item.stain_treatment_id} className={`d-flex flex-column align-items-center ${styles.shipment_card_main_box}`}>
                      <Card
                        key={item.stain_treatment_id}
                        title={item.title}
                        icon={IconData.Strain_shirt}
                        text={`${getStageWiseCount(item.stain_treatment_id)} items`}
                        count={IconData.Transfericon}
                        className={`${styles.strain_treatmentbox} border rounded text-break px-2 h-100`}
                        handleCircleClick={() => checkItemsToMove(item)}
                        main_class={`flex-1 w-100 h-100 `}
                      />
                      <div className="mt-4">
                        <span className="hoversvg cursor-pointer" onClick={() => handlePopup(item)}>
                          <Add />
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <button
            className="back-btn shadow-none "
            onClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
          >
            {t(CommonConstants.BACK)}
          </button>
        </div>
      </div>
      {startPopup && (
        <Popup
          title={t("Add items")}
          body={
            itemsUpperLimit > 0 ? (
              <StainTreatmentPopupBody
                item={state.stainTreatment.item}
                handleChange={handleItemChange}
                errors={errors.items}
                pendingItems={0}
                upperLimit={itemsUpperLimit}
              />
            ) : (
              <span>{errors.items}</span>
            )
          }
          yestext={itemsUpperLimit > 0 ? t(CommonConstants.SAVE) : ""}
          notext={t(CommonConstants.BACK)}
          submit={saveStainTreatment}
          cancel={() => {
            setStartPopup(false), setState((prevState) => ({
              ...prevState,
              stainTreatment: {
                ...prevState.stainTreatment,
                item: 0,
                stage_id: '',
                type: '',
              },
            }));
          }}
        />
      )}
      {movePopup && (
        <Popup
          title={t("Move items")}
          body={
            <div className={`d-flex flex-wrap justify-content-center internal_laundary_move_item_body`}>
              {errors.moveItem
                ? errors.moveItem
                : LaundryInternalStages?.filter(
                  (item: any) => item.type !== "stain_treatment"
                ).map((item, index) => {
                  return (
                    <AdminCard
                      key={index}
                      title={t(item?.title)}
                      handleClick={() => prepareItemsToMove(item)}
                      icon={item.icon}
                      selected={item.stage_id == selectedMoveStage ? styles.move_selected : ""}
                    />
                  );
                })}
              {errors.general && <div className="text-center w-100">{errors.general}</div>}
            </div>
          }
          modalclass={errors.moveItem ? "lg" : "transfer_to_modal"}
          yestext={errors.moveItem ? "" : t(CommonConstants.SAVE)}
          notext={t(CommonConstants.BACK)}
          submit={() => moveItems()}
          cancel={() => { setMovePopup(false), setSelectedMoveStage(null) }}
        />
      )}
    </Layout>
  );
};

export default StainTreatmentOrganism;
