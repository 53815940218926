import React, { ReactNode, useEffect, useState } from "react";
import Shipmentheader from "./Shipmentheader";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

type LayoutProps = {
  children: ReactNode;
  pagename: string;
  logo: any;
  timer: boolean;
  comments?: any;
  hold?: any;
  stage?: string;
  ongoing?: boolean;
  totaltime?: number;
  setTimerRunning?: any
};

const Layout: React.FC<LayoutProps> = ({
  logo,
  children,
  pagename,
  timer,
  comments,
  hold,
  stage,
  ongoing,
  totaltime,
  setTimerRunning
}) => {
  const searchParams = new URLSearchParams(location.search);

  return (
    <div className="row">
      <div className="col-md-11 ms-auto px-4">
        <WindowHeightComponent>
          <div className="d-flex flex-1 flex-column overflow-auto h-100">
            <Shipmentheader
              logo={logo}
              pagename={pagename}
              timer={timer}
              comments={comments}
              hold={hold}
              stage={stage}
              ongoing={ongoing}
              totaltime={totaltime}
              setTimerRunning={setTimerRunning}
            />
            <div className="flex-1 overflow-auto mt-2 d-flex flex-column h-100">
              <>{children}</>
            </div>
          </div>
        </WindowHeightComponent >
      </div>
    </div>
  );
};

export default Layout;
