import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import styles from "./dataentry.module.css"
import Button from '../../../components/atoms/Button'
import { CommonConstants, DataEntryConstants } from '../Constants/ShipmentConstants'
import { APICALL } from '../../../services/ApiServices'
import { fetchProductCatalogue } from '../../../routes/ApiEndPoints'
import { useNavigate } from 'react-router-dom'
import { t } from '../../Translations/TranslationUtils'
import { AutoComplete } from 'primereact/autocomplete';
import ProductCatalogueCard from '../../../components/atoms/ProductCatalogueCard'
import CommonShipmentServices from '../../../services/CommonShipmentServices'
import { WorkflowStageNames } from '../../../utils/constants/WorkflowStageNames'
import { useUserContext } from '../../../routes/Contextlib'
import ScreenDisable from '../../../utils/ScreenDisable'

const CreateDataEntryWithSku: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useUserContext();
    const localdata = CommonShipmentServices.getPostData();
    const [state, setState] = useState({ //state values
        search: '',
        lastsearched: '',
        searchtype: '',
        isfetched: false,
        timerrunning: true,
        data: {
            original: [],
            filtered: []
        },
        suggestions: {
            original: [],
            filtered: []
        }
    });

    useEffect(() => {
        if (user != null) {
            if (state.data.original.length == 0) {
                fetchData();
            }
            timeTracker(true);

            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        }
    }, [user]);

    const timeTracker = async (reloaded: boolean = false) => { // time tracker function
        let time = await CommonShipmentServices.saveTimerApi(
            "bp",
            localdata?.brand_partner_id,
            user?.user_id,
            WorkflowStageNames.DATAENTRY,
            reloaded
        );
    };

    const fetchData = async () => { // fetching all the values from the catalogue
        try {
            let postdata = {
                method: "POST",
                data: {
                    ...localdata,
                    format: true
                }
            };
            const response = await APICALL.service(
                fetchProductCatalogue,
                "POST",
                postdata,
                true
            );

            if (response?.status === 200) {
                let responsedata = response?.data?.data;

                setState((prevState: any) => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        original: responsedata?.data
                    },
                    suggestions: {
                        original: responsedata?.suggestions,
                        filtered: responsedata?.suggestions
                    }
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const search = (event: { query: string }) => { // onchange of the input filtering the suggestions
        if (state.timerrunning) {
            let filtered = state?.suggestions?.original?.filter((option: any) =>
                option.toLowerCase().includes(event.query.toLowerCase())
            );

            setState((prevState: any) => ({
                ...prevState,
                suggestions: {
                    ...prevState.suggestions,
                    filtered: filtered
                }
            }));
        }
    };

    const getSkuDetails = async () => { // handle submit
        if (state.timerrunning) {
            const groupedResults: any = {};
            // const filteredResults: any = [];

            state?.data?.original?.forEach((item: any) => { // filter and group the values based on the matched key
                const matchedKey = DataEntryConstants.SUGGESTION_KEYS.find(header =>
                    item[header]?.toLowerCase() === state?.search?.toLowerCase().trim()
                );

                if (matchedKey) {
                    if (!groupedResults[matchedKey]) {
                        groupedResults[matchedKey] = [];
                    }
                    groupedResults[matchedKey].push(item);
                    // filteredResults.push(item);
                }
            });

            let searchresults: any;
            let searched = '';
            let type = '';

            if (groupedResults.sku_number) { // if match found in sku_number, only those results will be shown
                type = 'sku';
                searched = 'sku_number';
                searchresults = groupedResults.sku_number;
            } else { // making the color variations
                type = 'color';
                searched = Object.keys(groupedResults)[0];
                searchresults = Object.values(groupedResults).flatMap(arr => arr);
                const uniqueHexObjects = searchresults.reduce((uniqueObjects: any, obj: any) => {
                    if (!uniqueObjects[obj.hex_color_code]) {
                        uniqueObjects[obj.hex_color_code] = obj;
                    }
                    return uniqueObjects;
                }, {});

                // Convert uniqueHexObjects back to an array of objects
                searchresults = Object.values(uniqueHexObjects);
            }

            setState((prevState: any) => ({
                ...prevState,
                search: state?.search?.trim(),
                isfetched: true,
                lastsearched: searched,
                searchtype: type,
                data: {
                    ...prevState.data,
                    filtered: searchresults
                }
            }));
        }
    }

    const handleSelect = (value: any) => { // handling the click of card
        if (state.timerrunning) {
            if (state.searchtype === 'sku' || state.searchtype === 'size') {
                navigate(`/data-entry/overview?id=${value.product_catalogue_id}`)
            } else { // making the size variations
                const results = state?.data?.original?.filter((item: any) =>
                    item.hex_color_code === value.hex_color_code && item[state.lastsearched] === value[state.lastsearched]
                );
                setState((prevState: any) => ({
                    ...prevState,
                    isfetched: true,
                    searchtype: 'size',
                    data: {
                        ...prevState.data,
                        filtered: results
                    }
                }));
            }
        }
    }

    const handleClear = () => { // handling clear filter
        setState((prevState: any) => ({
            ...prevState,
            search: '',
            lastsearched: '',
            searchtype: '',
            isfetched: false,
            data: {
                ...prevState.data,
                filtered: []
            }
        }))
    }

    const setTimerRunning = (timer: boolean) => { // changing the state based on the timer pause and play
        setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
    }

    return (
        <Layout
            pagename={t('Data entry')}
            logo={true}
            timer={true}
            stage={WorkflowStageNames.DATAENTRY}
            ongoing
            setTimerRunning={setTimerRunning}
        >
            <div className="position-relative">
                <ScreenDisable display='none' />
                {/* search row starts*/}
                <div className="d-flex align-items-start my-3">
                    <div className={`${styles.sku_title}`}>
                        {t(DataEntryConstants.ENTER_SEARCH_VALUE)}
                    </div>
                    <div className='w-50 mx-xxl-5 mx-xl-4 mx-lg-3 mx-md-3 mx-3'>
                        <AutoComplete
                            value={state?.search}
                            suggestions={state?.suggestions?.filtered}
                            completeMethod={search}
                            onChange={(e) =>
                                setState((prevState: any) => ({
                                    ...prevState, search: e.value
                                }))
                            }
                        />
                    </div>
                    <div className='d-flex'>
                        <Button
                            title={t(CommonConstants.SEARCH)}
                            className={`${styles.check_btn} me-4`}
                            handleClick={getSkuDetails}
                        />
                        <Button
                            title={t(CommonConstants.CLEAR)}
                            className={`${styles.clear_btn}`}
                            handleClick={handleClear}
                        />
                    </div>
                </div>
                {/* search row ends*/}
                {/* sku details  starts*/}
                {state?.data?.filtered?.length !== 0 ? <div className='border rounded p-4 flex-1 overflow-auto shadow'>
                    <div className="row">
                        {state?.data?.filtered?.map((value: any) => (
                            <div key={value.product_catalogue_id}
                                className={`${state.searchtype === 'sku' ? 'col-6 col-xl-4' : 'col-4 col-xl-2'} mb-4`}>
                                <div className="shadow h-100 rounded border text-center cursor-pointer">
                                    <ProductCatalogueCard
                                        item={value}
                                        type={state.searchtype}
                                        handleClick={() => handleSelect(value)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div> : (state?.isfetched && <div className='col-12 text-center text-danger'>
                    {t(CommonConstants.NO_RESULTS_FOUND)}
                </div>)}
                {/* sku details  ends*/}
            </div>
        </Layout>
    )
}

export default CreateDataEntryWithSku;
