import LabelField from '../components/atoms/LabelField';
import InputTextfield from '../components/atoms/InputTextField';
import ErrorMessage from '../components/atoms/ErrorMessage';
import InputTextAreaField from '../components/atoms/InputTextAreaField';
import InputColorfield from '../components/atoms/InputColorField';
import InputRangefield from '../components/atoms/InputRangeField';
import MultiSelect from '../components/atoms/MultiSelectField';
import { Upload } from '../static/icons/Upload';
import styles from "../pages/MasterData/masterdata.module.css";
import ViewPreviewFile from '../components/molecules/ViewPreviewFile';
import CommonServices from './CommonServices';
import { t } from '../pages/Translations/TranslationUtils';
import Button from '../components/atoms/Button';

interface Option {
  value: number;
  label: string;
}

export const renderInput = (removeError: any, field: any, handleInputChange: any, formData: any, formErrors: any,
  disable = false, records = false) => {

  switch (field.type) {
    case 'text':
      return <div className={records == true ? 'row' : 'col-6 mb-4'}>
        <div className={records == true ? "col-4 d-flex align-items-center" : ''}>
          <LabelField
            title={t(CommonServices.capitalizeLabel(field.name))}
            mandatory={field.required}
          />
        </div>
        <div className={records == true ? "col-8" : ''}>
          <InputTextfield
            name={field.name}
            className={`form-control shadow-none ${formErrors[field.name] ? "border-danger" : ""}`}
            value={formData[field.name]}
            handleChange={handleInputChange}
            handleClick={() => removeError(field.name)}
            readOnly={disable}
          />
          <ErrorMessage
            errorMessage={t(formErrors[field.name])}
          />
        </div>
      </div>;

    case 'file':
      return <div className='col-6 mb-4'>
        <LabelField
          title={t(CommonServices.capitalizeLabel(field.name))}
          mandatory={field.required}
        />
        <LabelField
          htmlforid={field.name}
          title={<Upload />}
          className='px-2 hoversvg cursor-pointer'
        />
        <input
          name={field.name}
          type="file"
          accept={field.accept}
          onChange={handleInputChange}
          id={field.name}
          onClick={() => removeError(field.name)}
        />
        <ViewPreviewFile
          message={t(field.message)}
          file={formData[field.name]}
          accept={field.accept}
          classname={`${styles.preview_image}`} />
        <ErrorMessage
          errorMessage={t(formErrors[field.name])}
        />
      </div>;

    case 'textarea':
      return <div className='col-6 mb-4'>
        <LabelField
          title={t(CommonServices.capitalizeLabel(field.name))}
          mandatory={field.required}
        />
        <div>
          <InputTextAreaField
            name={field.name}
            className={'form-control shadow-none '}
            handleChange={handleInputChange}
            value={formData[field.name]}
          />
        </div>
        <ErrorMessage
          errorMessage={t(formErrors[field.name])}
        />
      </div>;

    case 'color':
      return <div className='col-6 mb-4'>
        <LabelField
          title={t(CommonServices.capitalizeLabel(field.name))}
          mandatory={field.required}
        />
        <InputColorfield
          name={field.name}
          className={'form-control shadow-none'}
          handleChange={handleInputChange}
          value={formData[field.name]}
        />
        <ErrorMessage
          errorMessage={t(formErrors[field.name])}
        />
      </div>;

    case 'range':
      return <div className='col-6 mb-4'>
        <LabelField
          title={t(CommonServices.capitalizeLabel(field.name))}
          mandatory={field.required}
        />
        <InputRangefield
          name={field.name}
          className={` ${formErrors[field.name] ? "border-danger" : ""}`}
          handleChange={handleInputChange}
          value={formData[field.name]}
          handleClick={() => removeError(field.name)}
          max="100"
        />
        <ErrorMessage
          errorMessage={t(formErrors[field.name])}
        />
      </div>;

    case 'select':
      return <div className={records == true ? 'row' : 'col-6 mb-4 formselect'}>
        <div className={records == true ? "col-4 d-flex align-items-center" : ''}>
          <LabelField
            title={t(CommonServices.capitalizeLabel(field.name))}
            mandatory={field.required}
          />
        </div>
        <div className={records == true ? "col-8" : ''}>
          <MultiSelect
            name={field.name}
            className={`shadow-none`}
            classNamePrefix={'react-select-prefix'}
            placeholder={t('select')}
            options={field.options}
            standards={field.options.find((option: Option) => option.value == formData[field.name])}
            handleChange={(e) => handleInputChange(e, field.name)}
            handleClick={() => removeError(field.name)}
            customStyle={
              formErrors[field.name]
                ? { border: "1px solid red", borderRadius: "5px" }
                : undefined
            }
            isClearable={field.name != 'date_format'}
            isSearchable
            disabled={disable}
          />
          <ErrorMessage
            errorMessage={t(formErrors[field.name])}
          />
        </div>
      </div>;

    case 'multi-select':
      return <div className='col-6 mb-4 formselect'>
        <LabelField
          title={t(CommonServices.capitalizeLabel(field.name))}
          mandatory={field.required}
        />
        <MultiSelect
          name={field.name}
          className={'shadow-none'}
          classNamePrefix={'react-select-prefix'}
          placeholder={t('select')}
          options={field.options}
          standards={field.options.filter((option: Option) =>
            formData[field.name]?.includes(option.value)
          )}
          handleChange={(selectedValues) => handleInputChange(
            (selectedValues as any[]).map((option) => option.value), field.name
          )}
          customStyle={
            formErrors[field.name]
              ? { border: "1px solid red", borderRadius: "5px" }
              : undefined
          }
          handleClick={() => removeError(field.name)}
          isClearable
          isSearchable
          isMulti
        />
        <ErrorMessage
          errorMessage={t(formErrors[field.name])}
        />
      </div>;

    case 'buttonwithpopup':
      return <div className={records == true ? 'row' : 'col-6 mb-4 formselect'}>
        <div className={records == true ? "col-4 d-flex align-items-center" : ''}>
          <LabelField
            title={t(CommonServices.capitalizeLabel(field.name))}
            mandatory={field.required}
          />
        </div>
        <div className={records == true ? "col-8" : ''}>
          <>{console.log()}</>
          <Button
            name={field.name}
            title={field.options.find(
              (option: any) =>
                option?.value == formData[field.name]
            )?.label}
            className={`text-truncate w-100 back-btn d-block ${formErrors[field.name] ? "border-danger" : ""}`}
            disabled={disable}            
            handleClick={() => handleInputChange(field.name)}
          />
          <ErrorMessage
            errorMessage={t(formErrors[field.name])}
          />
        </div>
      </div>;

    default:
      return null;
  }
};