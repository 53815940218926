import React, { useState, useEffect } from "react";
import { t } from "../Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../components/atoms/CustomAlert";
import Edit from "../../static/icons/Edit";
import { FaSort } from "react-icons/fa";
import Pagination from "../../services/Paginations";
import { APICALL } from "../../services/ApiServices";
import {
  checkBpArchiveStatus,
  fetchAllBrandPartnerUrl,
} from "../../routes/ApiEndPoints";
import { CommonData } from "./components/constants/brandPartnerConst";
import AccessControl from "../../services/AccessControl";
import { useUserContext } from "../../routes/Contextlib";
import Archive from "../../static/icons/Archive";
import Unarchive from "../../static/icons/Unarchive";
import Popup from "../../utils/popup";
import InputTextfield from "../../components/atoms/InputTextField";
import Button from "../../components/atoms/Button";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import InputWithButton from "../../components/molecules/InputWithButton";
import { CONSTANTS } from "../../applicationConstants/AppConstants";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

const ManageBrandPartner: React.FC = () => {
  const [bpDetails, setBpDetails] = useState<any[]>([]);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<"active" | "inactive" | "draft">(
    "active"
  );
  const [state, setState] = useState({
    limit: CONSTANTS.PAGINATION_LIMIT,
    offset: 0,
    totalLength: 1,
    totalPages: 1,
  });
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState(false);
  const { user, setUser } = useUserContext();
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  const [selectedBrand, setSelectedBrand] = useState({
    status: true,
    showPopUp: false,
    id: null,
    issaved: null,
  });

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      CustomAlert("success", storageMessage, 6000);
    }
  });

  const handleEdit = (e: number) => {
    const url = `/brand-partner/create/?edit=${e}`;
    navigate(url);
  };

  const handleTabClick = (tab: "active" | "inactive" | "draft") => {
    setState((prev) => ({ ...prev, offset: 0 }))
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handleSortClick = async () => {
    if (Object.values(bpDetails).length > 0) {
      setCurrentPage(1);
      !isSorted && setIsSorted(true);
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      await getBrandPartners('sort', sortOrder === "asc" ? "desc" : "asc");
    }
  };

  const handlePaginationClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const newOffset = (pageNumber - 1) * state.limit;
    setState((prevData) => ({
      ...prevData,
      offset: newOffset,
    }))
  };

  async function getBrandPartners(filter?: any, order?: any) {
    //Get brand all partners
    try {
      let data: any = {
        manage: true,
        status: activeTab,
        search: searchText,
        offset: (filter === 'sort' ? 0 : state.offset),
        limit: state.limit
      }
      if (filter === 'sort' || isSorted) {
        data.order = ['title', order ?? sortOrder]
      }
      let postData = {
        method: "POST",
        data: data,
      };
      const response = await APICALL.service(
        fetchAllBrandPartnerUrl,
        "POST",
        postData,
        true
      );

      if (response?.status !== 200) {
        throw new Error("Request failed");
      }
      setBpDetails(response.data.data);
      const totalPages = Math.ceil(
        response?.data?.totalLength / state.limit
      );
      setState((prev) => ({ ...prev, 
        totalLength: response?.data?.totalLength, 
        totalPages: totalPages,  
        offset: filter === 'sort' ? 0 : state.offset
      }))
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBrandPartners();
  }, [state.offset, activeTab, searchText]);

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "brand partner"
    );
    let permissions = [
      {
        permission: "brand partner",
        read: true,
      },
    ];
    if (truePerm?.update === true || truePerm?.delete === true) {
      permissions[0].read = false;
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  const confirmUpadateBrand = async () => {
    // To archive/unarchive brand
    try {
      const status = await checkArchiveStatus();
      if (status) {
        const postdata = {
          brand_partner_id: selectedBrand.id,
          status: selectedBrand.status.toString(),
        };
        const response = await APICALL.service(
          process.env.REACT_APP_MASTER_DATA_URL +
          "api/master/create-brand-partner",
          "POST",
          postdata
        );

        if (response?.status === 200) {
          setSelectedBrand((prev) => ({ ...prev, showPopUp: false }));
          // (selectedBrand.status) && setActiveTab('inactive');
          !selectedBrand.status && setActiveTab("inactive");
          selectedBrand.status && setActiveTab("active");
          CustomAlert("success", t("Brand partner updated successfully"), 6000);
        } else {
          setSelectedBrand((prev) => ({ ...prev, showPopUp: false }));
          CustomAlert("error", t("Error while updating brand partner"), 6000);
        }
      } else {
        setSelectedBrand((prev) => ({ ...prev, showPopUp: false }));
        CustomAlert('error', t('Shipments linked to this brand partner are currently in progress'), 6000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkArchiveStatus = async () => {
    //To check shipments related to selected brand
    if (selectedBrand.status) {
      return true;
    } else {
      try {
        const postData = {
          bp_id: selectedBrand.id,
        };
        const response = await APICALL.service(
          checkBpArchiveStatus,
          "POST",
          postData
        );
        if (response.status == 200) {
          return response.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={requiredPermissions}
    >
      <div className="row">
        <div className="col-md-11 ms-auto px-4">
          <WindowHeightComponent>
            <div className="d-flex flex-column overflow-auto h-100">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
                {t("Manage brand partner")}
              </div>

              <div className="form-group flex-1 overflow-auto h-100">
                <div className="d-flex flex-column h-100 overflow-auto manage-roles-table mx-0">
                  <div className="">
                    <div className="user-tabs my-3">
                      <Button
                        className={`${activeTab === "active" ? "active" : ""
                          } border-0 bg-transparent tab-btn me-4`}
                        handleClick={() => handleTabClick("active")}
                        title={t("Active")}
                      />
                      <Button
                        className={`${activeTab === "inactive" ? "active" : ""
                          } border-0 bg-transparent tab-btn me-4`}
                        handleClick={() => handleTabClick("inactive")}
                        title={t("Inactive")}
                      />
                      <Button
                        className={`${activeTab === "draft" ? "active" : ""
                          } border-0 bg-transparent tab-btn`}
                        handleClick={() => handleTabClick("draft")}
                        title={t("Draft")}
                      />
                      <div className="mb-3 float-end">
                        <InputWithButton
                          handleClick={(searchText) => { setState((prev) => ({ ...prev, offset: 0, })); setSearchText(searchText); setCurrentPage(1); }}
                          textFieldClass="form-control shadow-none"
                          buttonClass="submit-btn shadow-none"
                          noerror={true}
                          buttonTitle={t('Search')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 overflow-auto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="border-bottom-0">
                            {t("Name")}
                            <Button
                              handleClick={handleSortClick}
                              className="sort-btn border-0 bg-transparent text-gray"
                            >
                              <FaSort />
                            </Button>
                          </th>
                          <AccessControl
                            renderNoAccess={false}
                            requiredPermissions={[
                              {
                                permission: "brand partner",
                                update: true,
                                delete: true,
                              },
                            ]}
                            actions={true}
                            strict={false}
                          >
                            <th scope="col" className="border-bottom-0 text-center">
                              {t("Action")}
                            </th>
                          </AccessControl>
                        </tr>
                      </thead>
                      <tbody>
                        {bpDetails.length ? Object.values(bpDetails).map((item: any) => (
                          <tr key={item.brand_partner_id}>
                            <td className="text-break">{item.title}</td>
                            <AccessControl
                              renderNoAccess={false}
                              requiredPermissions={[
                                {
                                  permission: "brand partner",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="text-break manage-users-action text-center">
                                <AccessControl
                                  renderNoAccess={false}
                                  requiredPermissions={[
                                    {
                                      permission: "brand partner",
                                      update: true,
                                    },
                                  ]}
                                >
                                  <Button
                                    handleClick={() =>
                                      handleEdit(item.brand_partner_id)
                                    }
                                    id={item.brand_partner_id}
                                    className="shadow-none border-0 bg-transparent manage-users-action pe-4"
                                    data-toggle="tooltip"
                                    hoverText={t("Edit")}
                                  >
                                    <Edit />
                                  </Button>
                                </AccessControl>
                                {activeTab != "draft" && (
                                  <AccessControl
                                    renderNoAccess={false}
                                    requiredPermissions={[
                                      {
                                        permission: "brand partner",
                                        delete: true,
                                      },
                                    ]}
                                  >
                                    <Button
                                      handleClick={() =>
                                        setSelectedBrand((prev) => ({
                                          ...prev,
                                          status: !item.status,
                                          showPopUp: true,
                                          id: item.brand_partner_id,
                                          issaved: item.issaved,
                                        }))
                                      }
                                      className="me-4 px-0 border-0 bg-transparent shadow-none"
                                      data-toggle="tooltip"
                                      // data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      hoverText={
                                        item.status ? t("Deactivate") : t("Activate")
                                      }
                                    >
                                      {item.status ? <Archive /> : <Unarchive />}
                                    </Button>
                                  </AccessControl>
                                )}
                              </td>
                            </AccessControl>
                          </tr>
                        )) :
                          <td colSpan={2} className="text-center text-danger">
                            {t(CommonConstants.NO_RESULTS_FOUND)}
                          </td>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row position-relative mt-2 m-0">
                {state.totalLength > state.limit && state.totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={state.totalPages}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
                <div className="col-md-6 float-start p-0">
                  <button
                    className="back-btn shadow-none float-start text-decoration-none"
                    onClick={() => navigate("/brand-partner")}
                  >
                    {t(CommonData.backbutton)}
                  </button>
                </div>
              </div>
            </div>
          </WindowHeightComponent>
        </div>
      </div>
      {selectedBrand.showPopUp && (
        <Popup
          title={t("Confirmation")}
          body={t(
            `Are you sure you want to ${activeTab === "inactive" ? "activate" : "deactivate"
            } this brand?`
          )}
          cancel={() =>
            setSelectedBrand((prev) => ({ ...prev, showPopUp: false }))
          }
          submit={confirmUpadateBrand}
          yestext={t(CommonConstants.YES)}
          notext={t(CommonConstants.NO)}
        />
      )}
    </AccessControl>
  );
};

export default ManageBrandPartner;
