import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../routes/Contextlib";
import { useEffect, useState } from "react";
import { CommonConstants, ConfigConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";
import { APICALL } from "../../../services/ApiServices";
import { createCategorySortUrl, fetchCategorySortUrl, fetchList } from "../../../routes/ApiEndPoints";
import CommonServices from "../../../services/CommonServices";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import { WorkflowStageNames } from "../../../utils/constants/WorkflowStageNames";
import Layout from "../../../pages/Shipment/Layout";
import { t } from "../../../pages/Translations/TranslationUtils";
import ScreenDisable from "../../../utils/ScreenDisable";
import SortCounter from "../../molecules/SortCounterMolecule";
import styles from "../../../pages/Shipment/shimpment.module.css";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import customAlert from "../../atoms/CustomAlert";
import IconData from "../../../static/IconData";

const CategorySortOrganism = () => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const [state, setState] = useState({
        configSortingCategories: [],
        isSubmitted: false,
        saveRefresh: false,
        presentFlowCount: 0,
        totalCounts: 0,
        initialCounts: 0,
        timerrunning: true,
        nextFlowCount: 0,
        disableSaveButton: false
    });
    const localdata: any = CommonShipmentServices.getPostData();
    //Function to fetch sort data
    const fetchConfigSortCategories = async () => {
        try {
            let configreqdata = {
                method: "POST",
                data: {
                    list: [ConfigConstants.SORTCATEGORY],
                },
            };
            const configres = await APICALL.service(
                fetchList,
                "POST",
                configreqdata,
                true
            );
            if (configres?.status === 200) {
                setState((prevState) => ({
                    ...prevState,
                    configSortingCategories: CommonServices.sortAlphabattically(convertData(configres?.data?.SortCategory) ?? [], 'title'),
                }));
                const postdata = {
                    ...localdata
                }
                const response: any = await APICALL.service(fetchCategorySortUrl + `/${localdata?.brand_partner_id}`, 'POST', postdata);
                if (response?.status === 200) {
                    setState((prevState) => ({
                        ...prevState,
                        presentFlowCount: response?.presentflowcount,
                        initialCounts: response?.initialCounts
                    }));
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const convertData = (data: any) => {
        return data?.map((item: any) => {
            return {
                config_category_sort_id: item.sort_category_id,
                counts: 0,
                title: item.title
            }
        })
    }

    useEffect(() => {
        if (user != null) {
            // fetching the data from database
            fetchConfigSortCategories();
            timeTracker(true);

            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        };
    }, [user != null]);

    const timeTracker = async (reloaded: boolean = false) => {
        let time = await CommonShipmentServices.saveTimerApi(
            'bp',
            localdata?.brand_partner_id,
            user?.user_id,
            WorkflowStageNames.CATEGORYSORT,
            reloaded
        );
    }

    //To handle counts change
    const handleCountChange = (sortCategoryId: number, newCount: number) => {
        setState((prevState: any) => ({
            ...prevState,
            configSortingCategories: prevState.configSortingCategories?.map((item: any) => {
                if (item.config_category_sort_id === sortCategoryId) {
                    return { ...item, counts: newCount };
                }
                return item;
            }),
        }));
    };

    //To handle counts validations
    const validateCounts = async () => {
        let counts = state.configSortingCategories?.reduce((sum: number, item: any) => {
            return sum + Number(item.counts);
        }, 0);
        if (counts > state.presentFlowCount) {
            return `Counts are exceeding present flow count: ${state.presentFlowCount}`;
        } else if ((state.configSortingCategories?.filter((item: any) => item?.counts > 0).length === 0)) {
            return `Items are not added`;
        }
        return false;
    };
    //To handle submit
    const handleSubmit = async () => {
        try {
            const isValid: any = await validateCounts();
            if (!isValid) {
                setState(prevState => ({ ...prevState, disableSaveButton: true }));
                let postdata: any = {
                    ...localdata,
                    data: state.configSortingCategories.filter((item: any) => item?.counts > 0),
                }
                const response: any = await APICALL.service(createCategorySortUrl, 'POST', postdata);
                if (response?.status !== 200) {
                    setState(prevState => ({ ...prevState, disableSaveButton: false }));
                    throw new Error("Request failed");
                }
                navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")
            } else {
                customAlert('error', t(isValid), 6000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const goBackToPreviousPage = () => {
        // Implement your logic to navigate back to the previous page
        navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")
    };

    const setTimerRunning = (timer: boolean) => {
        setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
    }

    return (
        <Layout
            pagename={'Category sort'}
            logo={true}
            timer={true}
            stage={WorkflowStageNames.CATEGORYSORT}
            ongoing={!state.isSubmitted}
            setTimerRunning={setTimerRunning}
        >
            <div className='position-relative flex-1 overflow-auto'>
                <ScreenDisable display='none' />
                <div className="h-100 overflow-auto">
                    <div className="d-flex flex-wrap">
                        {
                            state.configSortingCategories?.map((category: any) => {
                                return (<div key={category?.sort_category_id} className={`${styles.config_sort_category} mb-2`}>
                                    <div className="p-2">
                                        {IconData.CreateCategorySort}
                                        <div className={`${styles.sortingheading} my-2 text-break`}>{category?.title}</div>
                                        <div className={` mb-0 ${styles.plus_minus}`}>
                                            <SortCounter
                                                count={category?.counts || 0}
                                                onCountChange={(newCount) => handleCountChange(category?.config_category_sort_id, newCount)}
                                                timer={state.timerrunning}
                                            />
                                        </div>
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>
            {/* Buttons */}
            <ActionButtonGroup
                backTitle={t(CommonConstants.BACK)}
                handleBackClick={goBackToPreviousPage}
                saveAndNextTitle={t(CommonConstants.SAVE)}
                handleSaveAndNextClick={handleSubmit}
                saveDisabled={state.disableSaveButton}
            />
        </Layout>
    );
};

export default CategorySortOrganism;
