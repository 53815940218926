import React, { useEffect, useState, useReducer, ChangeEvent } from 'react'
import { APICALL } from '../../../services/ApiServices';
import { APICALL as Axios } from '../../../services/AxiosServices';
import { fetchWorkArea, createqcissue } from '../../../routes/ApiEndPoints';
import CommonServices from '../../../services/CommonServices';
import { t } from '../../../pages/Translations/TranslationUtils';
import FormValidation from '../../../services/FormValidation';
import styles from "../../../pages/Shipment/QualityControl/Qualitycontrol.module.css";
import DivButtonAtom from '../../atoms/DivButtonAtom';
import { LabelWithButtonAction } from './LabelWithButtonAction';
import ErrorMessage from '../../atoms/ErrorMessage';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import DisplayBlockMolecule from '../DisplayBlockMolecule';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';

interface FormState {
    issue_id: any;
    zone_id: string;
    environment_id?: string,
    quality_control_id: string
    quality_control_issue_id: string
}

interface CreateIssueProps {
    handleSubmit?: (data: any, count: boolean) => void;
    qcissueobject?: any;
    configdata?: any;
    qcid: any
    count: any
    url?: any
    timer: boolean;
    markedarea: any;
    localdata: any;
}

const CreateIssue: React.FC<CreateIssueProps> = ({
    handleSubmit,
    qcissueobject,
    configdata,
    qcid,
    count,
    url,
    timer,
    markedarea,
    localdata
}) => {
    const initialForm = {
        quality_control_id: qcid,
        zone_id: qcissueobject.zone_id !== undefined ? qcissueobject.zone_id : "",
        environment_id: qcissueobject.environment_id !== undefined ? qcissueobject.environment_id : "",
        issue_id: qcissueobject.issue_id !== undefined ? qcissueobject.issue_id : "",
        quality_control_issue_id: qcissueobject.quality_control_issue_id !== undefined ? qcissueobject.quality_control_issue_id : ""
    };

    const [zones, setZones] = useState([]);
    const [workarea, setWorkArea] = useState([]);
    const [state, setStateValues] = useState<FormState>(initialForm);
    const [errors, setStateErrors] = useState({
        issue_id: "",
        zone_id: "",
        environment_id: ""
    })

    useEffect(() => {
        getZones();
    }, []);

    const getZones = async () => {
        try {
            const zones = (Object.keys(configdata.Zone).length > 0) ?
                configdata.Zone :
                await CommonServices.fetchMasterData("model", "Zone", true)
            if (zones != null) {
                setZones(zones)
            }
            if (qcissueobject != '') {
                await getWorkArea(qcissueobject.zone_id)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getWorkArea = async (zone: string, changeevent?: boolean) => {
        try {
            setStateValues((prev) => ({
                ...prev,
                zone_id: zone,
                environment_id: changeevent ? "" : (qcissueobject?.environment_id ?? "")
            }));
            setStateErrors({
                ...errors,
                zone_id: "",
                environment_id: ""
            });

            let workarea = null;
            let postdata = {
                'method': 'GET',
                "data": {}
            }
            let url: string;
            url = zone != 'all' ?
                fetchWorkArea + '/' + zone :
                fetchWorkArea;
            workarea = await APICALL.service(url, 'POST', postdata, true);

            if (workarea?.status == 200) {
                setWorkArea(workarea?.data);
            } else {
                setWorkArea([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleZone = async (event: ChangeEvent<HTMLInputElement>) => {
        if (timer) {
            if (state.zone_id && state.zone_id === event.target.id) {
                setStateValues((prev) => ({
                    ...prev,
                    zone_id: "",
                    environment_id: ""
                }));
                setWorkArea([]);
            } else {
                await getWorkArea(event.target.id, true);
            }
        }
    }

    const handleWorkarea = async (event: ChangeEvent<HTMLInputElement>) => {
        if (timer) {
            setStateValues((prev) => ({
                ...prev,
                environment_id: (state.environment_id === event.target.id) ? "" : event.target.id,
            }));
            setStateErrors({
                ...errors,
                environment_id: ""
            });
        }
    }

    const validate = (formData: any) => {
        const error: any = JSON.parse(JSON.stringify(errors));
        Object.entries(formData)?.forEach(([key, value]) => {
            if (error[key] != undefined) {
                if ((key === 'environment_id')) {
                    error[key] = workarea.length ? FormValidation.emptyValidation(value ?? '') : "";
                } else {
                    error[key] = FormValidation.emptyValidation(value ?? '');
                }
                setStateErrors((prevFormData) => ({ ...prevFormData, [key]: error[key] }));
            }
        });

        if (Object.keys(error).length !== 0) {
            return Object.values(error)?.every((errorMessage) => errorMessage === "");
        }
        return false;
    }

    const updateSelectedIssue = (issue: any) => {
        if (issue != null) {
            setStateValues({
                ...state,
                issue_id: issue.id
            });
            // CommonServices.handleMarkedAreaColor(markedarea, issue?.color)
            setStateErrors({
                ...errors,
                issue_id: "",
            });
        }
    }

    const handleTriggerEvent = (event: any) => {
        console.log(event);

    }
    const handleClose = (id: any) => {
        if (timer) {
            setStateValues({
                ...state,
                issue_id: "",
            });
        }
    }

    const handleSaveandBack = async (status: boolean) => {
        try {
            if (!status) {
                let isvalidated = validate(state);
                if (isvalidated) {
                    const formData: any = new FormData();
                    const statedata = JSON.parse(JSON.stringify(state));
                    const data = { ...localdata };
                    let postdata = {
                        ...data,
                        ...statedata
                    }
                    if (qcissueobject == '' || qcissueobject.length == 0) {
                        formData.append('count', Number(count + 1))
                        if (url.image != '') {
                            let blob: any = await CommonServices.convertBase64imageToBlob(url.image)
                            formData.append('image', blob)
                            formData.append('type', url.type)
                        }
                        postdata.markedarea = JSON.stringify(markedarea.currentstate)
                    }
                    Object.entries(postdata).forEach(([key, value]) => {
                        formData.append(key, value)
                    });

                    const response: any = await Axios.service(createqcissue, 'POST', formData);

                    let submitdata: any = ''
                    if (response?.status === 200 && response?.data?.data != null) {
                        let data = response?.data?.data;
                        submitdata = {
                            'issueslist': data?.issueslist,
                            'image': {
                                'url': data?.url,
                                'type': url.type
                            }
                        }
                    }
                    if (handleSubmit) {
                        handleSubmit(submitdata, true);
                    }
                }
            } else {
                if (handleSubmit) {
                    handleSubmit("", false)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='d-flex flex-column h-100'>
            <div className='flex-1'>
                <LabelWithButtonAction label_text={t("Select identified issue")}
                    setSelectedItem={updateSelectedIssue}
                    selectedItem={state?.issue_id}
                    list={configdata.IssueType}
                />
                {/* second part is handled with direct usage of atom here */}
                {
                    (state?.issue_id && state.issue_id !== '' && state.issue_id !== null) ?
                        <DivButtonAtom
                            // key={selectedIssue.id}
                            id={String(state.issue_id)}
                            title={CommonShipmentServices.getFilteredTitle(configdata.IssueType, 'issue_type_id', state?.issue_id)}
                            blockStyle={styles.activeblocks}
                            handleTriggerEvent={handleTriggerEvent}
                            handleClose={handleClose}
                        />
                        : null
                }
                <ErrorMessage
                    errorMessage={t(errors.issue_id)}
                />

                {/* zone block starts */}
                {
                    zones && Object.keys(zones).length > 0 &&
                    <DisplayBlockMolecule
                        handleTriggerEvent={handleZone}
                        state={state}
                        all={true}
                        list={zones}
                        errors={errors}
                        idname="zone_id"
                        label={t("Zones")}
                        mandatory={true}
                    />
                }
                {/* zone block ends */}

                {/* Work area starts */}
                {
                    state.zone_id !== null && Object.keys(workarea).length > 0 &&
                    <DisplayBlockMolecule
                        handleTriggerEvent={handleWorkarea}
                        state={state}
                        all={false}
                        list={workarea}
                        errors={errors}
                        idname="environment_id"
                        label={t("Environment")}
                        mandatory
                    />
                }
                {/* Work area ends */}
            </div>
            <div className="d-flex justify-content-between mt-2">
                <button
                    onClick={() => handleSaveandBack(true)}
                    className="back-btn shadow-none text-decoration-none"
                >
                    {t(CommonConstants.BACK)}
                </button>
                <button
                    onClick={() => handleSaveandBack(false)}
                    className="submit-btn shadow-none "
                >
                    {t(CommonConstants.SAVE)}
                </button>
            </div>
        </div>
    )
}

export default CreateIssue;