import React, { Component, MouseEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { t } from "../pages/Translations/TranslationUtils";

interface PopupProps {
  title?: string;
  body: React.ReactNode;
  cancel?: () => void;
  submit?: () => void;
  close?: () => void;
  yesicon?: React.ReactNode;
  noicon?: React.ReactNode;
  yestext?: string;
  notext?: string;
  modalSize?: string;
  bodyclassName?: string;
  modalclass?: string;
}

class Popup extends React.Component<PopupProps> {
  constructor(props: PopupProps) {
    super(props);
    this.state = {
      status: 0,
    };
  }

  render() {
    const {
      title,
      body,
      cancel,
      submit,
      close,
      yestext,
      notext,
      yesicon,
      noicon,
      modalSize,
      bodyclassName,
      modalclass,
    } = this.props;
    return (
      <Modal
        size={`${modalSize ?? "lg"}`}
        show={true}
        centered
        className={modalclass}
        onHide={close ?? cancel}
      >
        <Modal.Header>
          {title && <Modal.Title className="">{title}</Modal.Title>}
          <Button
            variant="link"
            className="btn-close shadow-none mt-1"
            onClick={close ?? cancel}
            aria-label="Close"
            title={t("Close")}
          >
            <AiFillCloseCircle />
          </Button>
          
        </Modal.Header>
        <Modal.Body className={`text-center text-break mb-3 px-0 ${bodyclassName}`}>
          {body}
        </Modal.Body>
        {(notext || yestext) && (
          <Modal.Footer className={`border-0 p-0 justify-content-${yestext && notext ? "between" : "center"} mb-3`}>
            {notext && (
              <Button
                variant="secondary"
                className="btn back-btn shadow-none d-flex align-items-center"
                onClick={cancel}
              >
                {noicon && noicon} {notext}
              </Button>
            )}
            {yestext && (
              <Button
                className="btn submit-btn shadow-none  d-flex align-items-center"
                onClick={submit}
              >
                {yesicon && yesicon}
                {yestext}
              </Button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default Popup;
