import React, { useState, useEffect } from "react";
import styles from "../../pages/Shipment/shimpment.module.css";
import Sort from "../../static/icons/shipment/Sort";
import Reportssmall from "../../static/icons/shipment/Reportssmall";
import Layout from "../../pages/Shipment/Layout";
import { useNavigate } from "react-router-dom";
import Popup from "../../utils/popup";
import { t } from "../../pages/Translations/TranslationUtils";
import PlayIcon from "../../static/icons/shipment/Play";
import Close from "../../static/icons/Close";
import DataEntry from "../../static/icons/shipment/DataEntry";
import QualityControl from "../../static/icons/shipment/QualityControl";
import CreateShipmenticon from "../../static/icons/shipment/CreateShipmenticon";
import Manageicon from "../../static/icons/shipment/Manageicon";
import Card from "../molecules/ShipmentDashboardCard";
import { getShipments } from "../../routes/ApiEndPoints";
import { APICALL } from "../../services/ApiServices";
import {
  CommonConstants,
  ShipmentConstants,
  SortingConstants,
} from "../../pages/Shipment/Constants/ShipmentConstants";
import CommonShipmentServices from "../../services/CommonShipmentServices";
import { useUserContext } from "../../routes/Contextlib";
import CommonServices from "../../services/CommonServices";
import Repair from "../../static/icons/shipment/Repair";
import IconData from "../../static/IconData";
import customAlert from "../atoms/CustomAlert";
import AccessControl from "../../services/AccessControl";
import { ScannerMolecule } from "../molecules/ScannerMolecule";

export const ShipmentDashboardOrganism = () => {
  const { user } = useUserContext();
  const searchParams = new URLSearchParams(location.search);
  const editId = searchParams.get("edit");
  const navigate = useNavigate();
  const [state, setState] = useState({
    shipment_id: null,
    navigateUrl: "",
    popup_heading: "",
    noshipments: false,
    shipmentdata: [],
    scannererror: false
  });
  const [showPopup, setShowPopup] = useState({
    timer: false,
    internal_laundry: false,
    scanner: false
  });
  const [shipmentDetails, setShipmentDetails] = useState({});
  const localdata = CommonShipmentServices.getPostData();
  let bpdet: any = localStorage.getItem("bp_details");
  const bpDetails = bpdet != null ? JSON.parse(bpdet) : "";
  const [workflowStages, setWorkflowStages] = useState<string[]>([]);
  const bpPermissiondata = localStorage.getItem("bp_permissions_details");
  const bpPermissionDetails =
    bpPermissiondata != null ? JSON.parse(bpPermissiondata) : "";
  const [refresh, setRefresh] = useState(false);
  const ignonestages = ['Publishing', 'External laundry'];
  const [userPermissions, setUserPermissions] = useState<any>()

  // Constants for shipment types
  const shipmentTypes = [
    { title: ("Sort"), count: 0, icon: <Sort />, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/shipment/sort/create", Number(shipmentId), "sorting", localStorage.getItem('singleshipment') ? 'scanner' : 'timer', stage_id) },
    { title: ("Category sort"), count: 0, icon: IconData.CategorySort, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/shipment/category-sort/create", Number(shipmentId), "category_sorting", localStorage.getItem('singleshipment') ? 'scanner' : 'timer', stage_id) },
    { title: ("Internal laundry"), count: 0, icon: IconData.InternalLaundary, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/laundry-internal", Number(shipmentId), "internal_laundry", localStorage.getItem('singleshipment') ? 'scanner' : 'internal_laundry', stage_id) },
    { title: ("Data entry"), count: 0, icon: <DataEntry />, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/data-entry/create", Number(shipmentId), "data_entry", localStorage.getItem('singleshipment') ? 'scanner' : '', stage_id) },
    { title: ("Quality control"), count: 0, icon: <QualityControl />, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/shipment/quality-control/manage", Number(shipmentId), "quality_control", '', stage_id) },
    { title: ("Repair"), count: 0, icon: <Repair />, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/shipment/repair/manage", Number(shipmentId), "repair", '', stage_id) },
    { title: ("Quality assurance"), count: 0, icon: IconData.QualityAsurance, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/quality-assurance/manage", Number(shipmentId), "quality_assurance", '', stage_id) },
    { title: ("External laundry"), count: 0, icon: IconData.ExternalLaundary, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/laundry-external/batch-overview", Number(shipmentId), "external_laundry", '', stage_id) },
    { title: ("Finishing"), count: 0, icon: IconData.Finishing, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/finishing/manage", Number(shipmentId), "finishing", '', stage_id) },
    { title: ("Merchandising"), count: 0, icon: <DataEntry />, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/merchandising/manage", Number(shipmentId), "merchandising", '', stage_id) },
    { title: ("Photo studio"), count: 0, icon: IconData.photostudio, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/photo-studio/manage", Number(shipmentId), "photo_studio", '', stage_id) },
    { title: ("Photo editing"), count: 0, icon: IconData.PhotoEdit, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/photo-editing/manage", Number(shipmentId), "photo_editing", '', stage_id) },
    { title: ("Publishing"), count: 0, icon: IconData.Pre_publish, handleOnclick: (shipmentId: any, stage_id: any) => handleNextStep("/publishing", Number(shipmentId), "publishing", 'timer', stage_id) },
  ];

  useEffect(() => {
    if (user) {
      const userPerm: any = user.permissions;
      setUserPermissions(userPerm)
    }
  }, [user != null])

  const checkStatus = (title: any) => {
    const truePerm = userPermissions?.find((item: any) => item.permission.toLowerCase().trim() === title)
    if (truePerm?.update === true || truePerm?.delete === true) {
      return false
    } else {
      return true
    }
  }

  // Constants for small cards
  const smallCards = [
    {
      title: t("Manage shipments"), icon: <Manageicon />, singleshipment: false, handleOnclick: () => navigate("/shipment/manage"),
      requiredPermissions: [
        {
          permission: "shipment",
          read: checkStatus("shipment"),
        },
      ]
    },
    {
      title: t("Single shipment"), icon: IconData.SingleShipment, singleshipment: localStorage.getItem('singleshipment') ? true : false, handleOnclick: () => navigate("/shipment-dashboard"),
      requiredPermissions: [
        {
          permission: "shipment",
          read: true,
        },
      ]
    },
    {
      title: t("Create shipment"), icon: <CreateShipmenticon />, singleshipment: false, handleOnclick: () => navigate("/shipment/create"),
      requiredPermissions: [
        {
          permission: "shipment",
          create: true,
        }
      ]
    },
    {
      title: t("Reports"), icon: <Reportssmall />, singleshipment: false, handleOnclick: () => { navigate("/reports/shipment") },
      requiredPermissions: [
        {
          permission: "shipment reports",
          read: true,
        },
      ],
    },
  ];

  const handleScanPopup = (scandata: any) => {
    const isScandataInShipmentdata = state.shipmentdata && scandata
      ? state.shipmentdata.some((item: any) => item.barcode_id === (scandata?.barcode_id ?? scandata))
      : false;
    if (isScandataInShipmentdata) {
      setState((prev) => ({ ...prev, scannererror: false }));
      const filteredData = state.shipmentdata?.filter((item: any) => item.barcode_id === (scandata?.barcode_id ?? scandata));
      let shipmentid: any = filteredData?.length > 0 ? filteredData.map((item: any) => item?.shipment_id) : null;
      setState((prev: any) => {
        return {
          ...prev,
          shipment_id: shipmentid[0]
        };
      });
      setShowPopup((prev) => ({ ...prev, scanner: false, }));
      if (state.popup_heading === 'internal_laundry') {
        setShowPopup((prev) => ({ ...prev, internal_laundry: true }));
      } else if (state.popup_heading === 'sorting' || state.popup_heading === 'category_sorting') {
        setShowPopup((prev) => ({ ...prev, timer: true }));
      } else {
        submitPopup(shipmentid[0], state?.navigateUrl);
      }
    } else {
      setState((prev) => ({ ...prev, scannererror: true }))
    }
  };

  const handlePopup = (response: boolean) => {
    setShowPopup((prev) => ({ ...prev, internal_laundry: false }));
    if (response) {
      submitPopup(null, "/laundry-internal/stain-treatment");
    } else {
      submitPopup(null, "/laundry-internal/washing");
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pathWithParams = url.pathname + url.search;
    localStorage.setItem("shipment_dashboard_url", pathWithParams)
    if (localdata.brand_partner_id == "") {
      navigate(`/shipment`);
    }
    fetchShipment();
  }, [editId]);

  useEffect(() => {
    const successStorageMessage = localStorage.getItem("errorAlertMessage");
    if (successStorageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", t(successStorageMessage), 6000);
    }
    const errorStorageMessage = localStorage.getItem("successAlertMessage");
    if (errorStorageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", t(errorStorageMessage), 6000);
    }
  }, [refresh]);

  async function fetchShipment() {
    try {
      const postData: any = {
        bpName: bpDetails?.title,
        brand_partner_id: bpDetails?.brand_partner_id,
      };
      if (editId) {
        postData.shipment_id = editId;
      } else {
        postData.combineAllShipment = true;
        postData.status = ['ready', 'in progress'];
      }
      const response = await APICALL.service(
        getShipments,
        "POST",
        postData
      );

      if (response.status === 200) {
        setWorkflowStages(response?.workflowstages);
        setShipmentDetails(response?.data);
        if (!editId) {
          setState((prev) => ({
            ...prev, shipmentdata: response?.shipments.map((item: any) => {
              return {
                ...item,
                value: item?.shipment_id,
                label: item?.barcode_id,
              }
            })
          }))
        } else {
          setState((prev) => ({ ...prev, shipmentdata: response?.data }))
        }
        if (response?.noshipments) {
          setState((prev) => ({ ...prev, noshipments: true }))
        }
        if (editId) {
          localStorage.removeItem('singleshipment');
          localStorage.setItem('shipment_details', JSON.stringify(response?.data[0]));
        } else {
          localStorage.setItem('singleshipment', 'true');
          localStorage.removeItem('shipment_details');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  //To show popup and setting state
  const handleNextStep = async (
    navigateUrl: string,
    shipmentId: any,
    popup_heading: string,
    popup: string,
    stage_id: any
  ) => {
    const access = checkAccess(stage_id)
    if (access) {
      setState({
        ...state,
        navigateUrl: navigateUrl,
        shipment_id: shipmentId,
        popup_heading: popup_heading,
      });
      if (popup) {
        setShowPopup((prev) => ({ ...prev, [popup]: true }));
      } else {
        submitPopup(shipmentId, navigateUrl);
      }
    } else {
      localStorage.setItem(
        "errorAlertMessage",
        t(`You do not have access to this stage, please contact admin.`)
      );
      setRefresh(!refresh);
    }
  };

  const checkAccess = (stage_id: any) => {
    let access = false;
    const hasAdminOrSupervisorRole = user?.roles?.some(
      (role) =>
        role.toLowerCase().trim() === process.env.REACT_APP_ADMIN ||
        role.toLowerCase().trim() === process.env.REACT_APP_SUPERVISOR
    );

    if (hasAdminOrSupervisorRole) {
      access = true;
    } else {
      access = bpPermissionDetails[stage_id]?.some(
        (item: any) => item.user_id === user?.user_id
      );
    }
    return access;
  }

  //Confirmation for popup
  const submitPopup = async (shipment_id = null, navigateUrl = "") => {
    const navigateShipmentId =
      shipment_id !== null ? shipment_id : (state?.shipment_id?.[0] ?? state?.shipment_id)
    if (navigateShipmentId && state.shipmentdata instanceof Array) {
      const specificShipment = state.shipmentdata.filter(
        (detail: any) => detail?.shipment_id === navigateShipmentId
      );
      localStorage?.setItem(
        "shipment_details",
        JSON.stringify(specificShipment[0])
      );
    }
    setShowPopup((prev) => ({ ...prev, timer: false }))
    navigate(navigateUrl != "" ? navigateUrl : state.navigateUrl);
  };

  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <Layout pagename={t("Dashboard")} logo={true} timer={false}>
        {showPopup.timer && (
          <Popup
            title={""}
            body={t(
              `${SortingConstants.POPUP_BODY
              } ${CommonServices.removeUnderScore(state.popup_heading)}?`
            )}
            cancel={() => setShowPopup((prev) => ({ ...prev, timer: false }))}
            submit={() => submitPopup()}
            yestext={t(`${CommonConstants.YES_POPUP}`)}
            notext={t(CommonConstants.NO_POPUP)}
            yesicon={
              <span className={`${styles.sort_popup_icons} pe-2 mb-1`}>
                <PlayIcon />
              </span>
            }
            noicon={
              <span className={`${styles.sort_popup_icons} pe-2 mb-1`}>
                <Close />
              </span>
            }
          />
        )}
        <div
          className={`d-flex flex-column h-100 flex-1`}
        >
          <div
            className={`border rounded p-4 py-2 ${styles.ongoing_shipment_block}`}
          >
            {Object.keys(shipmentDetails).length > 0 ? (
              <div className={``}>
                {shipmentDetails &&
                  Object.values(shipmentDetails).map((item: any) => {
                    return (
                      <React.Fragment key={item.shipment_id}>
                        <div className={`${styles.label} text-break`}>
                          {item?.title ?? t(ShipmentConstants.STANDARD_WORKFLOW)}
                        </div>
                        <div className="d-flex flex-wrap">
                          {workflowStages?.map((stageName: any) => {
                            const matchingShipmentType = shipmentTypes.find(
                              (type) =>
                                type.title
                                  .toLowerCase()
                                  .replace(/\s+/g, "_") ===
                                stageName.stage_name
                            );

                            if (matchingShipmentType) {
                              const matchingStageCount =
                                item?.stage_count?.find(
                                  (stageCount: any) =>
                                    stageCount?.stage_name
                                      ?.toLowerCase()
                                      .replace(/\s+/g, "_") ===
                                    stageName.stage_name
                                );
                              const access = checkAccess(stageName.stage_id)
                              const count = matchingStageCount
                                ? matchingStageCount.count
                                : 0;
                              return (
                                <Card
                                  key={matchingShipmentType.title}
                                  title={t(matchingShipmentType.title)}
                                  count={count}
                                  icon={matchingShipmentType.icon}
                                  handleOnclick={() =>
                                  (!state.noshipments && (count > 0 ||
                                    ignonestages?.includes(matchingShipmentType.title)) &&
                                    matchingShipmentType.handleOnclick(
                                      item.shipment_id,
                                      stageName.stage_id
                                    ))
                                  }
                                  noAccess={!access}
                                  className={`${styles.box} ${access ? "" : styles.blur} border rounded`}
                                />
                              );
                            }
                            return null;
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            ) : (
              t("No ongoing shipments..")
            )}
          </div>
          <div className="d-flex">
            {smallCards.map((card, index) => (
              <AccessControl requiredPermissions={card.requiredPermissions}>
                {!card.singleshipment &&
                  <Card
                    key={card.title}
                    title={card.title}
                    icon={card.icon}
                    handleOnclick={card.handleOnclick}
                    className={`${styles.cus_small_card} border rounded py-3 px-1`}
                    smallCard={true}
                  />
                }
              </AccessControl>
            ))}
            {showPopup.internal_laundry && (
              <Popup
                title={t("Stain treatment")}
                body={t("Stain treatment required") + '?'}
                yestext={t(CommonConstants.YES)}
                notext={t(CommonConstants.NO)}
                submit={() => handlePopup(true)}
                cancel={() => handlePopup(false)}
                close={() => setShowPopup((prev) => ({ ...prev, internal_laundry: false }))}
              />
            )}
            {showPopup.scanner && (
              <Popup
                title={t("Scan shipment")}
                body={<ScannerMolecule error={state.scannererror} handleClick={handleScanPopup} search={false} select={state.shipmentdata} />}
                close={() => {
                  setState((prev) => ({ ...prev, scannererror: false }));
                  setShowPopup((prev) => ({ ...prev, scanner: false }))
                }}
                modalSize="xl"
              />
            )}
          </div>
        </div>
      </Layout>
    </AccessControl>
  );
};
