import React, { useEffect, useState } from "react";
import { t } from "../../../Translations/TranslationUtils";
import MultiSelectWithLabel from "../../../../components/molecules/MultiSelectWithLabel";
import { OptionValue } from "../../../../components/atoms/MultiSelectField";
import { APICALL } from "../../../../services/ApiServices";
import { fetchWorkflowStages, getUsernames } from "../../../../routes/ApiEndPoints";
import CommonServices from "../../../../services/CommonServices";
import { UTILITYFN } from "../utility/Utility";
import { GenerateRefreshToken } from "../../../../services/RefreshTokenService";
import FormValidation from "../../../../services/FormValidation";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { useNavigate } from "react-router-dom";
import styles from "../../brandpartner.module.css";
interface Props {
  draftSaved: boolean;
  saveAsDraft: string;
  saveAndNext: string;
  backbutton: string;
  formPageState?: any;
  dispatchFormPageState?: any;
  setPageDirection: (page: number) => void;
}

export interface Stage {
  stage_id: number;
  stage_name: string;
}


function SeventhTab(props: Props) {
  const {
    draftSaved,
    saveAsDraft,
    saveAndNext,
    backbutton,
    formPageState,
    dispatchFormPageState,
    setPageDirection
  } = props;

  function formReducer(prevState: any, newState: any) {
    return { ...prevState, ...newState }; // Corrected typo: 'newsState' to 'newState'
  }
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [bpPermission, setBpPermission] = useState<Record<number, { users: OptionValue[] }>>({});
  const [stages, setStages] = useState<Stage[]>([]);
  const [userOptions, setUserOptions] = useState<OptionValue[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchOperators();
    fetchStages();
  }, []);

  useEffect(() => {
    if (sessionStorage?.getItem("bpPermission")) {
      const storedPermissionData = JSON.parse(sessionStorage.getItem("bpPermission") || "[]");

      // Convert the stored permission data into the desired format
      const convertedPermission: Record<number, { users: OptionValue[] }> = {};
      storedPermissionData?.bpPermission?.forEach((permissionObj: any) => {
        const { stage_id, users } = permissionObj;
        const parsedStageId = parseInt(stage_id);
        if (!isNaN(parsedStageId)) {
          // Filter out null users before mapping
          const filteredUsers = users?.filter((user_id: number) => user_id !== null);
          // Map through filteredUsers and create an array of user objects with user_id and label properties
          const mappedUsers = filteredUsers.map((user_id: number) => {
            const user = userOptions.find((option) => option.value === user_id);
            return user && user;
          });

          convertedPermission[parsedStageId] = { users: mappedUsers };
        }
      });
      // Set the converted permission data into the bpPermission state
      setBpPermission(convertedPermission);
    }
  }, [userOptions])

  const fetchOperators = async () => {
    try {
      const postData = {
        method: 'POST',
        data: {
          permissions: ['workflow']
        }
      };
      const response = await APICALL.service(getUsernames, 'POST', postData, true);
      if (response?.status === 200) {
        const operatorUsers = response?.data?.map((user: any) => ({
          value: user?.user_id,
          label: user?.full_name
        }));
        // Sort the operatorUsers array alphabetically by the label property
        operatorUsers?.sort((a: any, b: any) => a?.label.localeCompare(b?.label));
        setUserOptions(operatorUsers);
      }
    } catch (error) {
      console.error("Error fetching operators:", error); // Changed error message
    }
  };

  const fetchStages = async () => {
    try {
      const response = await APICALL.service(fetchWorkflowStages, 'GET', '');
      if (response?.status === 200) {
        setStages(response.data);
      }
    } catch (error) {
      console.error("Error fetching stages:", error);
    }
  };

  const handleChange = (selectedUsers: OptionValue[], stageId: number) => {
    setBpPermission(prevPermissionState => ({
      ...prevPermissionState,
      [stageId]: { users: selectedUsers },
    }));
  };

  const checkRequiredFields = () => {
    const newFieldErrors: Record<string, string> = {};

    stages.forEach(stage => {
      const selectedUsers = bpPermission[stage.stage_id]?.users || [];
      const validationError = FormValidation.selectValidation(selectedUsers);
      if (validationError) {
        newFieldErrors[stage.stage_name] = validationError;
      }
    });
    setFieldErrors(newFieldErrors);
    if (Object.keys(newFieldErrors).length === 0) {
      return true;
    }
    return false;
  };

  //To remove error
  const removeError = (fieldName: string) => {
    // Handle the click event here
    setFieldErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  const validateFormData = async (
    event: React.MouseEvent<HTMLButtonElement>,
    props: Props,
    draft?: boolean
  ) => {
    event.preventDefault();
    const isValidated: boolean = !draft ? checkRequiredFields() : true;
    let updatedFormPageState = { ...formPageState };
    // Construct the bp_permissions array from bpPermission
    const bp_permissions = Object.keys(bpPermission).map(stage_id => ({
      stage_id: parseInt(stage_id), // Cast the stage_id to a number
      users: bpPermission[parseInt(stage_id)].users.map(user => user.value),
    }));

    if (!isValidated) {
      updatedFormPageState.pageSix.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      try {
        const bpId = Number(sessionStorage.getItem("brand_partner_id"));

        let token = await GenerateRefreshToken();
        const response = await fetch(
          process.env.REACT_APP_MASTER_DATA_URL +
          "api/master/create-brand-partner",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              "bp_permissions": bp_permissions,
              "brand_partner_id": bpId
            }),
          }
        );

        if (response?.status === 200) {
          if (draft) {
            navigate('/brand-partner/manage')
          }
          sessionStorage.setItem("bpPermission", JSON.stringify({ bpPermission: bp_permissions })); // Changed 'bpPermission' to 'bpPermission'
          updatedFormPageState.pageSix.validationStatus = true;
          dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
          UTILITYFN.setCurrentPageData(props, 7);
        }
      } catch (error) {
        console.error("Error validating form data:", error); // Improved error message
      }
    }
  };

  return (
    <>
      <form className="contract-date-form pt-0 pt-xxl-3 flex-1 overflow-auto h-100 d-flex flex-column">
        <div className="flex-1 overflow-auto">
          <div className="row m-0">
            {stages?.map(stage => (
              <div className={`col-6 ${styles.form_content}`} key={stage?.stage_id} >
                <MultiSelectWithLabel
                  title={`${t(CommonServices.capitalizeLabel(stage?.stage_name ?? ""))}: `}
                  mandatory={true}
                  name={stage?.stage_name}
                  handleChange={(selectedUsers: any) => handleChange(selectedUsers, stage.stage_id)}
                  options={userOptions}
                  standards={bpPermission[stage.stage_id]?.users || []}
                  errorMessage={fieldErrors[stage?.stage_name] ?? fieldErrors[stage?.stage_name]}
                  customStyle={
                    fieldErrors[stage?.stage_name]
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : undefined
                  }
                  handleClick={() => removeError(stage.stage_name)}
                  isClearable
                  isMulti
                  isSearchable
                />
              </div>
            ))}
          </div>
        </div>
        <ActionButtonGroup
          backTitle={t(backbutton)}
          saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
          saveAndNextTitle={t(saveAndNext)}
          handleBackClick={() => UTILITYFN.setCurrentPageData(props, 5)}
          handleSaveAsDraftClick={(e) => validateFormData(e, props, true)}
          handleSaveAndNextClick={(e) => validateFormData(e, props)}
        />
      </form>
    </>
  );
}

export default SeventhTab;
