import * as React from "react";
import "./App.css";
import "./static/variable.css";
import "./static/global.css";
import "./static/common.css";
import Routes from "./routes/AppRoutes";
import Login from "./pages/Login";
import UserProvider from "./routes/Providerlib";
import { FetchTranslations } from "./services/FetchTranslation";
import LoadingIcon from "./utils/LoadingIcon";
import { ProfilePictureProvider } from "./ProfileImage/ProfilePictureContext";
import InactivityTimeout from "./services/InactivityTimeout";
import TooManyRequestsPopup from "./utils/TooManyRequestsPopup";

const App: React.FC = () => {
  React.useEffect(() => {
    FetchTranslations();
  }, []);
  return (
    <div className="container-fluid">
      <div id="__next"></div>
      <div className="App">
        <UserProvider>
          <InactivityTimeout>
            <ProfilePictureProvider>
              <Routes />
            </ProfilePictureProvider>
            <Login />
            <TooManyRequestsPopup display="none" />
            <LoadingIcon display="none" />
          </InactivityTimeout>
        </UserProvider>
      </div>
    </div>
  );
};

export default App;
