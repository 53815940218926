import React, { useState, useEffect, useRef } from "react";
import { t } from "../Translations/TranslationUtils";
import { APICALL } from "../../services/ApiServices";
import styles from "./MediaLibrary.module.css";
import {
  fetchAllBrandPartnerUrl,
  fetchMediaLibraries,
  saveFiles,
  searchMedia,
} from "../../routes/ApiEndPoints";
import customAlert from "../../components/atoms/CustomAlert";
import MediaLibraryImagePopup from "../../components/molecules/ImagePopup/MediaLibraryImagePopup";
import S3Services from "../../services/S3Services";
import MediaLibraryFolderServices from "../../services/MediaLibraryFolderServices";
import MediaLibraryFolderMolecule from "../../components/molecules/MediaLibraryFolderMolecule";
import MediaLibraryFileMolecule from "../../components/molecules/MediaLibraryFileMolecule";
import MediaLibraryActionsMolecule from "../../components/molecules/MediaLibraryActionsMolecule";
import MediaLibraryTagsServices from "../../services/MediaLibraryTagsServices";
import FormValidation from "../../services/FormValidation";
import Popup from "../../utils/popup";
import { InputWithLabel } from "../../components/molecules/InputWithLabel";
import LabelField from "../../components/atoms/LabelField";
import { s3, S3_BUCKET_NAME } from "../../config/aws-config";
import ProgressBars from "./ProgressBar";
import Button from "../../components/atoms/Button";
import CommonShipmentServices from "../../services/CommonShipmentServices";
import AccessControl from "../../services/AccessControl";
import { useUserContext } from "../../routes/Contextlib";
import CommonServices from "../../services/CommonServices";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import ActionButtonGroup from "../../components/molecules/ActionButtonGroup";
import { useNavigate } from "react-router";
import EncryptDecryptService from "../../services/EncryptDecryptService";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

interface PathArrayInterface {
  value: string | number | null;
  label: string;
}

interface Props {
  modal?: boolean;
  handleImagePopupSubmit?: (modalimages: any[]) => void;
  closePopup?: (status: boolean) => void;
  selectedImages?: any[];
}

const MediaLibrary: React.FC<Props> = ({
  modal = false,
  handleImagePopupSubmit,
  selectedImages,
  closePopup,
}) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const userPermissions = user?.permissions;
  const [viewprogressbar, setViewProgressbar] = useState(false);
  const [homePage, setHomePage] = useState(true);
  const localdata = CommonShipmentServices.getPostData();
  const [folders, setFolders] = useState<any[]>([]);
  const [foldersTitles, setFoldersTitles] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [filesTitles, setFilesTitles] = useState<any[]>([]);
  const [pathArray, setPathArray] = useState<PathArrayInterface[]>([
    { value: 0, label: t("home") },
  ]);
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  const [folderId, setFolderId] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [doubleClicked, setDoubleClicked] = useState(false);
  const [renameId, setRenameId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const imageInputRef = useRef(null);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState<string>("");
  const [popupImageTitle, setPopupImageTitle] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [search, setSearch] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [tagInput, setTagInput] = useState("");
  const [imagePopupId, setImagePopupId] = useState<number>();
  const [existingTags, setExistingtags] = useState<string[]>([]);
  const [mediaTags, setMediaTags] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  const [highlightSuggestion, setHighlightSuggestion] = useState<any>();
  const [createFolderPopup, setCreateFolderPopup] = useState<Boolean>(false);

  const [tagError, setTagError] = useState<string>("");
  const [folderRenameError, setFolderRenameError] = useState<string>("");
  const [showDeleteFolderPopup, setShowDeleteFolderPopup] = useState({
    id: "",
    name: "",
    state: false,
    key: null,
  });
  const [modalimages, setModalImages] = useState<any>(selectedImages);
  const MEDIA_LIBRARY = "Media library";

  const setBpIdtoLocalStorage = async (id: any) => {
    const encId = EncryptDecryptService.encryptData(
      JSON.stringify({
        bp_id: id,
      })
    );
    localStorage.setItem("brandpartner_id", encId);
  };

  const bpIdfromLocalStorage = () => {
    const localBpId: any = localStorage.getItem("brandpartner_id") ?? "";
    const decryptedBpId = JSON.parse(
      EncryptDecryptService.decryptData(localBpId)
    );
    return decryptedBpId?.bp_id;
  };

  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const sortedFolders = [...folders].sort(sortByTitle(newSortOrder));
    const sortedFiles = [...files].sort(sortByTitle(newSortOrder));
    setFolders(sortedFolders);
    setFiles(sortedFiles);
  };

  function sortByTitle(order: string) {
    return (a: any, b: any) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();

      if (titleA < titleB) return order === "asc" ? -1 : 1;
      if (titleA > titleB) return order === "asc" ? 1 : -1;
      return 0;
    };
  }

  useEffect(() => {
    if (modal) {
      setBpIdtoLocalStorage(localdata.brand_partner_id);
      console.log('modal on line 141');
      setPathArray([
        { value: localdata.brand_partner_id, label: localdata.bpname },
      ]);
      setHomePage(false);
      setFolderId(localdata.brand_partner_id);
      getMediaLibraries(localdata.brand_partner_id);
    } else {
      getMediaLibraries('0');
    }
  }, []);

  async function getBrandPartners() {
    try {
      const postData = {
        method: "POST",
        data: { issaved: [true] },
      };
      const response = await APICALL.service(
        fetchAllBrandPartnerUrl,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        let data: any = CommonServices.sortAlphabattically(response?.data);
        setFolders(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getMediaLibraries(parent_id: any) {
    try {
      const postData = {
        method: "POST",
        data: {
          parent_id: parent_id,
        },
      };
      const response = await APICALL.service(
        fetchMediaLibraries,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        await setFolderFilesResponse(response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setFolderFilesResponse = async (response: any) => {
    let folder: any[] = [];
    let file: any[] = [];
    let folderTitles: string[] = [];
    let fileTitles: string[] = [];

    Object.values(response.data).forEach((item: any) => {
      if (item.type === "folder" || !item.type || item.type === 'bp') {
        folder.push(item);
        folderTitles.push(item.title.toLowerCase());
      } else if (item.type === "file") {
        file.push(item);
        fileTitles.push(item.title.toLowerCase());
      }
    });
    if (response.pathArray) {
      let patharray = response.pathArray;
      if (modal) {
        patharray = patharray.filter((path: any) => path.value !== 0);
      }
      setPathArray(patharray);
    }
    // Set folders, files, folderTitles, and fileTitles in state variables
    setFolders(folder);
    setFiles(file);
    setFoldersTitles(folderTitles);
    setFilesTitles(fileTitles);
  };

  const handleFolderClick = async (item: any) => {
    setSearchError("");
    const id = item.medialibrary_id
      ? item.medialibrary_id
      : item.brand_partner_id;
    await setBpIdtoLocalStorage(item.brand_partner_id);
    setHomePage(false);
    setFiles([]);
    setFolders([]);
    setFolderId(id);
    setSearch(false);
    setSearchText("");
    // const newElement: PathArrayInterface = {
    //   value: id,
    //   label: item.title,
    // };
    // if (!pathArray.some((obj) => obj.value === newElement.value)) {
    // setPathArray((prevArray) => [...prevArray, newElement]);
    // }
    getMediaLibraries(id);
  };

  const handlePathClick = (id: any) => {
    setSearchError("");
    setFolderId(id);
    setFiles([]);
    setFolders([]);
    setSearch(false);
    setSearchText("");
    const indexToRemove = pathArray.findIndex((obj) => obj.value === id);
    if (indexToRemove !== -1) {
      const newArray = pathArray.slice(0, indexToRemove + 1);
      setPathArray(newArray);
    }

    if (id === 0) {
      setHomePage(true);
      getMediaLibraries('0');    
    } else {
      setHomePage(false);
      getMediaLibraries(id);
    }
  };

  const handleCreateFolderPopup = () => {
    let error = FormValidation.nameValidation(inputValue);
    if (error == "") {
      error = foldersTitles.includes(inputValue.trim().toLocaleLowerCase())
        ? t("Folder name already exists")
        : "";
    }

    if (error) {
      setFolderRenameError(error);
    } else {
      setFolderRenameError("");
      handleCreateFolder();
    }
  };

  const handleCreateFolder = async () => {
    let response = await MediaLibraryFolderServices.createFolder(
      folderId,
      bpIdfromLocalStorage(),
      "folder",
      doubleClicked,
      "",
      inputValue,
      pathstringToArray()
    );
    if ((response.status = 200)) {
      setCreateFolderPopup(false);
      setInputValue("");

      getMediaLibraries(folderId);
      localStorage.setItem(
        "successAlertMessage",
        "New folder created successfully"
      );
      setRefresh(!refresh);
    }
  };

  const handleDeleteFolder = async () => {
    let path = pathstringToArray();
    path = path + showDeleteFolderPopup.name + "/";

    let response = await MediaLibraryFolderServices.deleteFolder(
      showDeleteFolderPopup.id,
      showDeleteFolderPopup.name
    );
    if (response.status === 200) {
      localStorage.setItem(
        "successAlertMessage",
        "Folder deleted successfully"
      );
      setRefresh(!refresh);
      if (search) {
        await handleSearch();
      } else {
        await getMediaLibraries(folderId);
      }
      setShowDeleteFolderPopup({
        id: "",
        name: "",
        state: false,
        key: null,
      });
    }
  };

  const pathstringToArray = () => {
    let pathstringArray = pathArray?.map((item) => {
      if (item.value != 0) {
        return item.label + "/";
      }
    });

    const pathstring = pathstringArray.join("");
    return pathstring;
  };

  const CheckFileFormat = (selectedFiles: File[] = []) => {
    const allowedFormats = [".png", ".jpg", ".jpeg"];
    let formaterror = "";
    let validFiles: File[] = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileExtension = "." + file?.name?.split(".")?.pop()?.toLowerCase();

      if (!allowedFormats.includes(fileExtension)) {
        formaterror = "Only png, jpg, and jpeg files are allowed.";
      }

      if (formaterror == "" && filesTitles.includes(file.name.toLowerCase())) {
        if (selectedFiles.length == 1) {
          formaterror = "File name already exists";
        }
      } else {
        validFiles.push(file);
      }
    }

    if (validFiles.length == 0) {
      formaterror = "File name already exists";
    }
    return { formaterror: formaterror, validFiles: validFiles };
  };

  const [progress, setProgress] = useState(0);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDoubleClicked(false);
    let fileList: File[] = event.target.files
      ? Array.from(event.target.files)
      : [];

    if (fileList) {
      const formatCheck = CheckFileFormat(fileList);
      fileList = formatCheck.validFiles;

      if (formatCheck.formaterror == "") {
        const pathstring =
          process.env.REACT_APP_AWS_DEFAULT_FOLDER + 'md/bp/'+ pathstringToArray();
        
        let filesdata = await uploadToS3(fileList, pathstring);
        let postdata: any = {
          method: "POST",
          data: {
            files: filesdata,
            parent_id: String(folderId),
            path: pathstring,
            brand_partner_id: bpIdfromLocalStorage(),
            type: "file",
          },
        };
        const response = await APICALL.service(
          saveFiles,
          "POST",
          postdata,
          true
        );
        if (response.status == 200) {
          getMediaLibraries(folderId);
          localStorage.setItem(
            "successAlertMessage",
            "File imported successfully"
          );
          setRefresh(!refresh);
        }
        setViewProgressbar(false);
      } else {
        localStorage.setItem("errorAlertMessage", formatCheck.formaterror);
        setRefresh(!refresh);
      }
    }
  };

  const uploadToS3 = async (fileList: any, pathstring: string) => {
    const totalfiles = fileList.length;
    let filesdata: any = [];
    setProgress(0);
    setViewProgressbar(true);
    if (fileList) {
      for (let i = 0; i < totalfiles; i++) {
        let changeName = false;
        let newName: string = "";
        let file = fileList[i];
        const timestamp = Math.floor(new Date().getTime() / 1000);
        const randomSuffix = Math.floor(Math.random() * 1000) + 1;
        const fileExtension = file.name.split('.').pop();
        const newFileName = `${timestamp}${randomSuffix}.${fileExtension}`;
        // if (filesTitles.includes(file.name.toLowerCase())) {
        // changeName = true;
        // // Extract file extension (if any) from the original file name
        // const fileExtension = file.name.includes(".")
        //   ? file.name.split(".").pop()
        //   : "";
        // console.log("fileExtension", fileExtension);
        // // Extract the base name (without extension) and append a number
        // const baseName = file.name.replace(
        //   new RegExp(`.${fileExtension}$`, "i"),
        //   ""
        // );
        // console.log("baseName",baseName);
        // newName = `${baseName}(${
        //   filesTitles.filter((name: any) => name.startsWith(baseName)).length
        // })${fileExtension}`;
        // console.log("newName",newName);
        // }

        const params = {
          Bucket: S3_BUCKET_NAME,
          Key: `${pathstring}${changeName ? newName : newFileName}`,
          Body: file,
          ContentType: file.type,
          ACL: "public-read",
        };
        // Wrap the S3 upload operation in a Promise
        const uploadPromise = () => {
          return new Promise((resolve, reject) => {
            s3.upload(params, (err: any, data: any) => {
              if (err) {
                console.error("Error uploading image:", err);
                reject(err);
              } else {
                console.log("Image uploaded successfully!", data.Location);
                filesdata.push({
                  filename: changeName ? newName : file.name,
                  url: data.Location,
                  key: `${pathstring}${changeName ? newName : newFileName}`,
                });
                resolve(data.Location);
              }
            });
          });
        };

        try {
          const uploadedLocation = await uploadPromise();
        } catch (error) {
          // Handle any errors from the S3 upload here
        }
        const newprogress = ((i + 1) / totalfiles) * 100;
        setProgress(newprogress);
      }
    }
    return filesdata;
  };

  const handleDeleteFiles = async () => {
    const postData = {
      key: showDeleteFolderPopup.key,
      medialibrary_id: showDeleteFolderPopup.id,
    };
    let response = await S3Services.deleteFromS3(postData);
    if (response.message == "File deleted successfully.") {
      localStorage.setItem("successAlertMessage", response.message);
      setRefresh(!refresh);
      const res = await getMediaLibraries(folderId);

      // if (response.status === 200) {
      setShowDeleteFolderPopup({
        id: "",
        name: "",
        state: false,
        key: null,
      });
      // }
    }
  };

  const handleDoubleClick = async (id: any, title: any) => {
    const access = await CommonServices.checkPermissions(
      userPermissions != undefined ? userPermissions : {},
      [
        {
          permission: "media library",
          update: true,
        },
      ],
      true,
      false
    );
    if (access) {
      setDoubleClicked(true);
      setRenameId(id);
      setInputValue(title);
    }
  };

  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");
    localStorage.removeItem("errorAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", t(storageMessage), 8000);
    }
  }, [refresh]);

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    localStorage.removeItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", t(storageMessage), 8000);
    }
  }, [refresh]);

  const handleBlur = async (title: string) => {
    let error = FormValidation.nameValidation(inputValue);

    if (title !== inputValue && error == "") {
      error = foldersTitles.includes(inputValue)
        ? "Folder name already exists"
        : "";
    }

    if (error == "" && title !== inputValue.trim().toLowerCase()) {
      setFolderRenameError("");
      const path = pathstringToArray();

      let resopnse = await MediaLibraryFolderServices.createFolder(
        null,
        null,
        "",
        null,
        renameId,
        inputValue,
        path
      );
      if (resopnse.status === 200) {
        getMediaLibraries(folderId);
        localStorage.setItem(
          "successAlertMessage",
          "Folder renamed successfully"
        );
        setRefresh(!refresh);
        setInputValue("");
      }
      setDoubleClicked(false);
      setRenameId("");
    } else {
      setInputValue("");
      setDoubleClicked(false);
      localStorage.setItem("errorAlertMessage", error);
      setRefresh(!refresh);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleImagePopup = (imageUrl: string, title: string, id: number) => {
    setImagePopupId(id);
    setPopupImageUrl(imageUrl);
    setPopupImageTitle(title);
    MediaLibraryTagsServices.fetchTags(id, setExistingtags, setMediaTags);

    if (modal) {
      const isMediaIdPresent = modalimages?.some(
        (item: any) => item.media_id === id
      );
      if (isMediaIdPresent) {
        // Remove the item with the matching media_id
        const updatedImages = modalimages?.filter(
          (item: any) => item.media_id !== id
        );
        setModalImages(updatedImages);
      } else {
        // Add the new item
        setModalImages([...modalimages, { media_url: imageUrl, media_id: id }]);
      }
    } else {
      setShowImagePopup(true);
    }
  };

  const handleTagSave = async () => {
    const tagsArray = findTags();
    const isExceedingLimit = tagsArray.some((str) => str.length > 255);
    let error;
    if (tagsArray.length != 0 && !isExceedingLimit) {
      let response = await MediaLibraryTagsServices.savetags(
        tagsArray,
        imagePopupId,
        setExistingtags,
        setTagInput
      );
      getMediaLibraries(folderId);
      setShowImagePopup(!showImagePopup);
    } else {
      isExceedingLimit
        ? (error = t("One or more tag is invalid"))
        : (error = t("No tags found"));
      setTagError(error);
    }
  };

  const handlePopupCancel = () => {
    setSuggestions([]);
    setTagInput("");
    setTagError("");
    setShowImagePopup(false);
  };

  const findTags = () => {
    const regex = /#\w+\s*\w*/g;
    const hashtags = (tagInput.match(regex) || []).map((tag) =>
      tag.toLowerCase()
    );
    return hashtags;
  };

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagInput(event.target.value);
    setTagError("");
    const inputValue = event.target.value.toLowerCase();
    let hashtag = "";
    let hashtagIndex = inputValue.lastIndexOf("#");
    if (hashtagIndex !== -1) {
      hashtag = inputValue.substring(hashtagIndex + 1);
    }

    if (inputValue != "") {
      const filteredData = mediaTags?.filter((item: any) => {
        const tag = item.title.toLowerCase();
        return tag.startsWith("#") && tag.substring(1).includes(hashtag);
      });
      const selectedTags = tagInput.split(" ");
      const unselectedTags = filteredData?.filter((suggestion: any) => {
        // Check if the suggestion's title is in the selectedTags array
        return !selectedTags.includes(suggestion.title);
      });

      setSuggestions(hashtagIndex !== -1 ? unselectedTags : []);
      // setSuggestions(hashtagIndex !== -1 ? unselectedTags : []);
    } else {
      setSuggestions([]);
    }
    setSelectedSuggestion(-1);
  };

  const handleSelectList = (suggestion: any) => {
    const segments = tagInput.split("#");
    const hashtag = segments.length > 1 ? segments[segments.length - 1] : "";
    const newValue = segments.slice(0, -1).join("#") + suggestion.title + " ";
    // Update the input value with the selected suggestion
    setTagInput(newValue);
    // Clear the suggestions
    setSuggestions([]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();

      setSelectedSuggestion((prevIndex) =>
        Math.min(prevIndex + 1, suggestions.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedSuggestion((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (event.key === "Enter" && selectedSuggestion !== -1) {
      event.preventDefault();
      handleSelectList(suggestions[selectedSuggestion]);
    }
  };

  useEffect(() => {
    setHighlightSuggestion(suggestions[selectedSuggestion]);
  }, [selectedSuggestion]);

  const handleRemoveTags = (id: any) => {
    MediaLibraryTagsServices.deleteTags(
      imagePopupId,
      id,
      setExistingtags,
      setMediaTags
    );
  };

  const handleCreateNewFolderPopupCancel = () => {
    setCreateFolderPopup(false);
    setInputValue("");
    setFolderRenameError("");
  };

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "media library"
    );
    let permissions = [
      {
        permission: "media library",
        read: true,
      },
    ];
    if (
      truePerm?.create === true ||
      truePerm?.update === true ||
      truePerm?.delete === true
    ) {
      permissions[0].read = false;
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  const handleSearch = async () => {
    // if (searchText === "") {
    //   setSearchError("Please enter any text");
    // }
    if ((searchText)?.trim() == "" && search === true) {
      setFolders([]);
      setFiles([]);
      setFoldersTitles([]);
      setFilesTitles([]);
      setSearch(false);
      getMediaLibraries(folderId);
    } else if ((searchText)?.trim() !== "") {
      setSearch(true);
      const postData = {
        method: "POST",
        data: {
          homePage: homePage,
          parent_id: folderId,
          searchText: (searchText)?.trim(),
        },
      };

      const response = await APICALL.service(
        searchMedia,
        "POST",
        postData,
        true
      );

      if (response.status == 200) {
        await setFolderFilesResponse(response);
      }
    }
  };
  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
      override={modal}
    >
      <div className="row">
        <div className={`${modal ? "" : "col-md-11 ms-auto px-4"}`}>

          <WindowHeightComponent className={`${modal && styles.medialibrary_popup}`}>
            <div className="d-flex flex-column h-100 overflow-auto">
              <div className={`${modal ? "" : "page-title"}`}>
                {modal ? "" : `${t(MEDIA_LIBRARY)}`}
              </div>
              <div className={`${modal ? "" : ""}`}>  {/*styles mediaheader*/}
                <div
                  className={`d-flex justify-content-between align-items-center `}
                >
                  <div className={`${styles.mediafolderpath} d-flex flex-wrap mw-50`}>
                    {pathArray.map((item: any, index: number) => (
                      <span
                        key={index} // or use a unique identifier if available
                        onClick={() => handlePathClick(item.value)}
                        className={`text-truncate ${styles.pathtext} border me-2 mb-1 p-1 px-3 rounded`}
                      >
                        {item.label}
                      </span>
                    ))}
                  </div>
                  <div className="flex-1">
                    {/*  */}
                    <MediaLibraryActionsMolecule
                      handleSortClick={handleSortClick}
                      sortOrder={sortOrder}
                      folderId={folderId}
                      setCreateFolderPopup={setCreateFolderPopup}
                      imageInputRef={imageInputRef}
                      handleFileUpload={handleFileUpload}
                      disable={viewprogressbar}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      handleSearch={handleSearch}
                      setSearchError={setSearchError}
                      searchError={searchError}
                    />
                  </div>
                </div>
                <div className={` ${modal ? "py-1" : "py-1"}`}>
                  {viewprogressbar && <ProgressBars progress={progress} />}
                </div>
              </div>

              <div className="row m-0 flex-1 overflow-auto">
                {/* Atom to display media folders */}
                {/* className={`border p-4 rounded shadow ${modal ? styles.mediabox : styles.mediaboxwithoutmodal
                    }`} */}
                <div
                  className={`d-flex flex-column p-0 overflow-auto h-100`}
                >
                  {folders.length > 0 || files.length > 0 ? (
                    <div className="px-3 py-2 border rounded shadow flex-1 overflow-auto">
                      <div className="row">
                        {Object.values(folders).map((item: any, index: number) => {
                          return (
                            <MediaLibraryFolderMolecule
                              parent_id={item.parent_id}
                              media_library_id={item.medialibrary_id}
                              title={item.title}
                              item={item}
                              homePage={homePage}
                              setShowDeleteFolderPopup={setShowDeleteFolderPopup}
                              brand_partner_id={item.brand_partner_id}
                              handleFolderClick={handleFolderClick}
                              folderId={folderId}
                              doubleClicked={doubleClicked}
                              renameId={renameId}
                              inputValue={inputValue}
                              handleInputChange={handleInputChange}
                              handleDoubleClick={handleDoubleClick}
                              handleBlur={() => handleBlur(item.title)}
                              modal={true}
                              autoFocus={true}
                              index={index}
                            />
                          );
                        })}
                        {/* Atom to display media files */}
                        {files &&
                          Object.values(files).map((item: any) => {
                            return (
                              <MediaLibraryFileMolecule
                                medialibrary_id={item.medialibrary_id}
                                Key={item.key}
                                url={item.url}
                                title={item.title}
                                handleImagePopup={handleImagePopup}
                                setShowDeleteFolderPopup={setShowDeleteFolderPopup}
                                modalimages={modalimages}
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <span className="px-3 py-2 border rounded shadow flex-1 overflow-auto">No data found</span>
                  )}

                  {!modal && folderId === 0 && (
                    <div className="">
                      <ActionButtonGroup
                        backTitle={t("Back")}
                        handleBackClick={() => navigate("/")}
                      />
                    </div>
                  )}
                </div>


                {/* Image popup atom to show enlarged image */}
                {showImagePopup && (
                  <MediaLibraryImagePopup
                    imageUrl={popupImageUrl}
                    title={popupImageTitle}
                    body
                    cancel={handlePopupCancel}
                    yestext={t(CommonConstants.SAVE)}
                    notext={t(CommonConstants.CANCEL)}
                    change={handleTagChange}
                    tagInput={tagInput}
                    submit={handleTagSave}
                    data={existingTags}
                    suggestions={suggestions}
                    selectList={handleSelectList}
                    handleKeyDown={handleKeyDown}
                    highlightSuggestion={highlightSuggestion}
                    handleRemoveTags={handleRemoveTags}
                    autoComplete={false}
                    error={tagError}
                  />
                )}
                {/* Popup to show options to delete forlder or files */}
                {showDeleteFolderPopup.state && (
                  <Popup
                    title={t(
                      `Delete ${showDeleteFolderPopup.key == null ? "folder" : "file"
                      }`
                    )}
                    body={
                      t('Are you sure you want to delete') + ` "${showDeleteFolderPopup.name}"?`
                    }
                    yestext={t("Delete")}
                    notext={t(CommonConstants.CANCEL)}
                    submit={
                      showDeleteFolderPopup.key == null
                        ? handleDeleteFolder
                        : handleDeleteFiles
                    }
                    cancel={() =>
                      setShowDeleteFolderPopup({
                        id: "",
                        name: "",
                        state: false,
                        key: null,
                      })
                    }
                  />
                )}
                {/* Popup to show options to create forlder or*/}
                {createFolderPopup && (
                  <Popup
                    title={t("Create new folder")}
                    body={
                      <div className="row w-100 align-items-center">
                        <div className="col-3">
                          {" "}
                          <LabelField title={t("Folder name") + ":"} mandatory />
                        </div>
                        <div
                          className="col"
                          onClick={() => setFolderRenameError("")}
                        >
                          <InputWithLabel
                            handleChange={handleInputChange}
                            value={inputValue}
                            error={folderRenameError}
                          />
                        </div>
                      </div>
                    }
                    yestext={t(CommonConstants.SAVE)}
                    notext={t(CommonConstants.CANCEL)}
                    submit={handleCreateFolderPopup}
                    cancel={handleCreateNewFolderPopupCancel}
                    bodyclassName={`${styles.createmediapopupbody}`}
                  />
                )}
              </div>


              {modal && (
                <div className="d-flex justify-content-between mt-4 px-4">
                  <Button
                    title={t(CommonConstants.CANCEL)}
                    className="back-btn"
                    handleClick={() => closePopup && closePopup(false)}
                  />
                  <Button
                    title={t(CommonConstants.SAVE)}
                    className="submit-btn"
                    handleClick={() =>
                      handleImagePopupSubmit && handleImagePopupSubmit(modalimages)
                    }
                  />
                </div>
              )}
            </div>
          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl>
  );
};

export default MediaLibrary;
