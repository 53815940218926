import { useState, useEffect } from "react";
import searchicon from "../static/icons/Searchheader.svg";
import quickaccess from "../static/icons/Plusheader.svg";
import dashboard from "../static/icons/Dashboardheader.svg";
import notification from "../static/icons/Notificationheader.svg";
import qrscan from "../static/icons/QRscan.svg"
import "./header.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { GenerateRefreshToken } from "../services/RefreshTokenService";
import EncryptDecryptService from "../services/EncryptDecryptService";
import { t } from "../pages/Translations/TranslationUtils";
import ProfilePictureUpload from "../ProfileImage/ProfilePicture";
import ProfileImage from "../ProfileImage/ProfileImage";
import { useUserContext } from "../routes/Contextlib";
import Popup from "../utils/popup";
import QuickAccessBody from "../components/organisms/QuickAccessBody";
import { APICALL } from "../services/ApiServices";
import { fetchLanguagesUrl } from "../routes/ApiEndPoints";
import { OptionValue } from "../components/atoms/MultiSelectField";

const Header: React.FC = () => {
  const [profilepictureurl, updateProfilePicture] = useState("");
  const { user } = useUserContext();
  const user_id = user?.user_id
  let userlan = localStorage.getItem("activeLanguage");

  const [formValues, setFormValues] = useState({
    name: user?.name,
    user_language: userlan != null ? userlan : "",
  });

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [shortcutMenuOpen, setShortcutMenuOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const [showQuickAccessPopup, setShowQuickAccessPopup] = useState(false);
  const [languageOptions, setLanguageOptions] = useState<OptionValue[]>([]);

  useEffect(() => {
    if (localStorage.getItem('catalogue_bp')) {
      let productcatalogue = ['/product-catalogue/manage', '/product-catalogue'];
      if (!productcatalogue.includes(window.location.pathname)) {
        localStorage.removeItem('catalogue_bp');
      }
    }
    let profileurl: any = localStorage.getItem("profilepicture");
    if (profileurl != null)
      updateProfilePicture(profileurl);
  })

  useEffect(() => {
    // Fetch user details from the backend using the userId
    const fetchUserDetails = async () => {
      if (user != null) {
        let profileurl: any = localStorage.getItem("profilepicture");
        if (profileurl == null) {
          profileurl =
            user.profilepic != "" && user.profilepic != undefined
              ? user.profilepic
              : require("../ProfileImage/profile.png");
          localStorage.setItem("profilepicture", profileurl);
        }
        updateProfilePicture(profileurl);

        if (userlan == null) {
          userlan = user?.user_language;
          localStorage.setItem("activeLanguage", userlan);
        }
        setFormValues({
          ...formValues,
          name: user?.name,
          user_language: userlan,
        });

        // localStorage.setItem('idupdated', 'true')
      }
    };
    let idupdated = localStorage.getItem("idupdated");
    if (user != undefined && idupdated == null) {
      fetchUserDetails();
    }
  }, [user != undefined]);

  const handleLanguageChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = e.target.value;

    try {
      let token = await GenerateRefreshToken();
      const response = await fetch(
        process.env.REACT_APP_SERVICE_REGISTRY +
        "service/b-identity-manager/create-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            method: "POST",
            data: EncryptDecryptService.encryptData(
              JSON.stringify({
                user_id: user_id,
                user_language: newLanguage,
              })
            ),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }
      // Update the active language in local storage
      localStorage.setItem("activeLanguage", newLanguage);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const storedOptions = localStorage?.getItem('languageOptions');
    if (storedOptions) {
      try {
        const options = JSON.parse(storedOptions);
        setLanguageOptions(options);
      } catch (error) {
        fetchLanguages();
      }
    } else {
      fetchLanguages();
    }
  }, []);
  //To fetch language options
  const fetchLanguages = async () => {
    try {
      const postData = {
        // Encrypting the request data
        method: "GET",
        data: {},
      };
      const response = await APICALL.service(fetchLanguagesUrl, 'POST', postData, true)
      if (response?.status !== 200) {
        throw new Error("Request failed");
      }

      // Decrypting the response
      const mappedLanguages = response.data
        .map((item: any) => ({
          value: item.language_code,
          label: item.language_name,
        }));
      // Sort roles alphabetically by label
      mappedLanguages.sort((a: any, b: any) => a.label.localeCompare(b.label));
      setLanguageOptions(mappedLanguages);
      localStorage?.setItem('languageOptions', JSON.stringify(mappedLanguages));
    } catch (error) {
      console.error(error);
    }
  }

  //Function to open content box based on type
  const setContentBox = (type: any) => {
    if (type === "shortcut") {
      setShortcutMenuOpen(!shortcutMenuOpen);
      setNotificationMenuOpen(false);
      setAccountMenuOpen(false);
    } else if (type === "notification") {
      setNotificationMenuOpen(!notificationMenuOpen);
      setShortcutMenuOpen(false);
      setAccountMenuOpen(false);
    } else if (type === "quickaccess") {
      setShowQuickAccessPopup(true);
    } else {
      setAccountMenuOpen(!accountMenuOpen);
      setShortcutMenuOpen(false);
      setNotificationMenuOpen(false);
    }
  };

  //List of nav icons
  const IconsList = [
    // { icon: searchicon, url: "#", type: "", title: "Search" },
    // { icon: dashboard, url: "#", type: "", title: "Dashboard" },
    { icon: quickaccess, url: "#", type: "quickaccess", title: "Quick access" },
    // { icon: notification, url: "#", type: "notification", title: "Notifications" },
  ];

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    // Event handling logic
    window.location.href = "/logout";
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="shadow-sm">
        <div
          className="py-4 pt-3 px-2 d-flex border-bottom border-3"
          title="Please select logo of 512 x 512 upto 2mb"
        >
          <ProfilePictureUpload url={profilepictureurl} />
          <div className="d-flex align-items-center">
            <p className="mb-2 font16px profile_name text-truncate">
              {formValues?.name}
            </p>
          </div>
        </div>
        <div className="py-2 pb-1">
          <a href="/users/myprofile" className="dropdown-item text-gray my-3">
            <div className="d-flex align-items-center">
              <img
                src="../images/Myaccount.svg"
                alt="profile image"
                className="my-rofile-icon"
              />
              <span className="ms-3">{t("My profile")}</span>
            </div>
          </a>

          <button className="dropdown-item text-gray" onClick={handleClick}>
            <img
              src="../images/Logout.svg"
              alt="logout"
              className="my-rofile-icon"
            />
            <span className="ms-3">{t("Logout")}</span>
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="col-md-11 px-0 ms-auto">
        <header>
          <nav className="navbar navbar-expand-md navbar-light bg-light">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                  {IconsList.map((val, index) => {
                    return (
                      <li
                        key={index}
                        className="nav-item"
                        onClick={() =>
                          val.type !== "" ? setContentBox(val.type) : ""
                        }
                        title={t(val.title)}
                      >
                        <a className="navbar-brand p-0 pt-1" href={val.url}>
                          <img
                            alt={"NAV_ICONS"}
                            className="header-icon"
                            src={val.icon}
                          ></img>
                        </a>
                      </li>
                    );
                  })}
                  <li className="me-3">
                    <select
                      id="language"
                      name="language"
                      value={formValues.user_language}
                      onChange={handleLanguageChange}
                      className="language_dropdown"
                    >
                      {languageOptions?.map((language: any) => (
                        <option
                          className="py-2"
                          key={language.value}
                          value={language.value}
                        >
                          {language.label}
                          {/* <hr /> */}
                        </option>
                      ))}
                    </select>
                  </li>

                  <li className="nav-item dropdown profile-dropdown cursor-pointer">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom"
                      overlay={popover}
                    >
                      <span>
                        <ProfileImage profilepictureurl={profilepictureurl} />
                      </span>
                    </OverlayTrigger>
                  </li>
                </ul>

                {/* Content menus for different data defined below */}
                {shortcutMenuOpen && (
                  <div className="shortcut-menu">
                  </div>
                )}
                {notificationMenuOpen && (
                  <div className="notification-menu col-md-4 p-0">
                  </div>
                )}
                {accountMenuOpen && (
                  <div className="account-menu">
                  </div>
                )}
              </div>
            </div>
          </nav>
        </header>
      </div>
      {showQuickAccessPopup && (
        <div className="quickaccess">
          <Popup
            title={t("Quick access")}
            body={<QuickAccessBody />}
            cancel={() => setShowQuickAccessPopup(false)}
            notext={t("Close")}
            bodyclassName="quickaccessbody"
            modalclass="quickasscessmodalcontent"
          />
        </div>
      )}
    </>
  );
};

export default Header;
