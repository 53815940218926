import React, { useEffect, useState, ReactNode } from "react";
import { APICALL } from "./ApiServices";
import { fetchTimeout } from "../routes/ApiEndPoints";
type Props = {
  children?: ReactNode;
};

const InactivityTimeout: React.FC<Props> = ({ children }) => {
  const [inactiveTime, setInactiveTime] = useState<number>(0);
  const [timeout, setTimeout] = useState<number>(15);

  useEffect(() => {
    const fetchTimeoutTime = async () => {
      try {
        const postData = {
          method: "GET",
          data: {},
        };
        const response = await APICALL.service(fetchTimeout, "POST", postData, true);
        if (response.status == 200) {
          setTimeout(response.data.timeout);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTimeoutTime();
  }, []);

  const resetTimer = () => {
    setInactiveTime(0);
  };

  useEffect(() => {
    const activityEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

    const handleActivity = () => {
      resetTimer();
    };

    activityEvents.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    const checkIntervalInSeconds = 5;
    const inactivityThresholdInMinutes = timeout;

    const timer = setInterval(() => {
      setInactiveTime((prevTime) => prevTime + checkIntervalInSeconds);

      if (inactiveTime >= inactivityThresholdInMinutes * 60) {
        window.location.href = "/logout";
      }
    }, checkIntervalInSeconds * 1000); // Convert seconds to milliseconds

    return () => {
      clearInterval(timer);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, [inactiveTime]);

  return <>{children}</>;
};

export default InactivityTimeout;
