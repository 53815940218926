import React, { useState, useEffect } from 'react'
import Layout from '../../../pages/Shipment/Layout';
import { ImageView } from '../../molecules/QualityControlMolecules/ImageView';
import { ProductInfo } from '../../molecules/QualityControlMolecules/ProductInfo';
import { t } from "../../../pages/Translations/TranslationUtils";
import { useNavigate } from 'react-router-dom';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import { getPhotoStudioUrl, photoStudioSaveUrl, fetchConfigData } from '../../../routes/ApiEndPoints'
import { APICALL } from '../../../services/ApiServices'
import Button from '../../atoms/Button';
import styles from "../../../pages/Shipment/shimpment.module.css";
import IconData from '../../../static/IconData';
import customAlert from '../../atoms/CustomAlert';
import Timer from '../../atoms/Timer';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import { WorkflowStageNames } from '../../../utils/constants/WorkflowStageNames';

const PhotoStudioOrganism: React.FC = () => {
    const localdata = CommonShipmentServices.getPostData();
    const searchParams = new URLSearchParams(location.search);
    const serialNumber: any = searchParams.get("id");
    const navigate = useNavigate();
    const [photoStudioInfo, setPhotoStudioInfo] = useState<any>([]);
    const [stage, setStage] = useState<any>();
    const [running, setRunning] = useState(true);
    const [totaltime, setTotalTime] = useState(0);
    const [commentData, setCommentData] = useState({
        type: "renewal_record",
        type_id: null,
        stagename: "photo_studio",
        commentdata: {}
    });
    useEffect(() => {
        fetchPhotoStudioInfo();
    }, [serialNumber != undefined])

    const fetchPhotoStudioInfo = async () => {
        try {
            const postdata = {
                ...localdata,
                serialNumber: serialNumber
            }
            const response = await APICALL.service(getPhotoStudioUrl, 'POST', postdata);

            if (response?.status == 200) {
                setCommentData({ ...commentData, type_id: response?.data?.renewal_id, commentdata: response?.commentdata })
                let config = {
                    method: "POST",
                    data: {
                        type: "model",
                        name: "ProductType",
                        id: response?.data?.product_type_id
                    },
                };
                const configresponse: any = await APICALL.service(
                    fetchConfigData,
                    "POST",
                    config,
                    true
                );

                if (configresponse?.status === 200) {
                    let resdata = response?.data;
                    resdata.back_image = configresponse?.data.back_image ?? '';
                    resdata.front_image = configresponse?.data.front_image ?? '';
                    setPhotoStudioInfo(resdata);
                }
                else {
                    setPhotoStudioInfo(response?.data)
                }

                setStage(response?.data?.presentStageId)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (event: any) => {
        try {
            const postData = {
                ...localdata,
                serial_number: serialNumber
            }

            const response = await APICALL.service(photoStudioSaveUrl, 'PUT', postData);

            if (response?.status == 200) {
                navigate('/photo-studio/manage');
            } else {
                customAlert(
                    "error",
                    t("Something went wrong while saving data"),
                    6000
                )
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBack = () => {
        navigate(`/photo-studio/manage`);
    }

    const handlePausePlay = async () => {
        try {
            let pause = await CommonShipmentServices.saveTimerApi(
                'renewal',
                commentData?.type_id,
                localdata?.user_id,
                WorkflowStageNames.PHOTO_STUDIO,
                !running
            );
            pause && setRunning(!running);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (localdata?.user_id != null && commentData?.type_id != null) {
            timeTracker(true);

            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        }
    }, [localdata?.user_id != null && commentData?.type_id != null]);

    const timeTracker = async (reloaded: boolean = false) => {
        let time = await CommonShipmentServices.saveTimerApi(
            'renewal',
            commentData?.type_id,
            localdata?.user_id,
            WorkflowStageNames.PHOTO_STUDIO,
            reloaded
        );
    }

    return (
        <Layout
            pagename={t('Photo studio')}
            logo={true}
            timer={false}
            comments={commentData}
            hold={true}
        >

            <div className="flex-1 overflow-auto">
                <div className="row m-0 h-75">
                    {/* left block start */}
                    <div className="col-6">
                        <div className="py-4 pb-2 px-5 border rounded shadow h-100 d-flex flex-column h-100">
                            <h3 className='text-gray'>{photoStudioInfo?.serial_number}</h3>

                            {/* image block start */}
                            <div className={`${styles.photoimg} flex-1 overflow-auto`}>
                                <ImageView
                                    disable={false}
                                    front_image={photoStudioInfo?.front_image}
                                    back_image={photoStudioInfo?.back_image}
                                />
                            </div>
                            {/* image block ends */}

                            {/* Product info block start */}
                            <ProductInfo
                                dataentry={photoStudioInfo}
                            />
                            {/* Product info block end  */}

                        </div>
                    </div>
                    {/* left block ends */}

                    {/* right block starts */}
                    <div className="col-6">
                        <div className="py-4 pb-2 px-5 border rounded shadow h-100 position-relative">
                            <div className={`border rounded w-100 text-center mt-5 py-3`}>
                                <Timer time={totaltime} running={running} className={styles.photostudiotimer} />
                            </div>
                            <div className="d-flex justify-content-center py-5 p-xxl-5 p-xl-3">
                                <div className="d-flex align-items-center justify-content-center w-75 px-xxl-5 px-xl-2">
                                    <span className='cursor-pointer' onClick={handlePausePlay}>{running ? IconData.photostudiopause : IconData.photostudioplay}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* right block ends */}
                </div>
            </div>

            <div className="row m-0">
                <div className="col-6">
                    <Button
                        title={t(CommonConstants.BACK)}
                        className="back-btn shadow-none float-start mt-2"
                        handleClick={handleBack}
                    />
                </div>
                <div className="col-6">
                    {stage == photoStudioInfo?.stage_id &&
                        <Button
                            title={t(CommonConstants.SAVE_AND_SCAN_NEXT)}
                            className="submit-btn shadow-none float-end mt-2"
                            handleClick={handleSubmit}
                        />
                    }
                </div>

            </div>
        </Layout>
    )
}

export default PhotoStudioOrganism